import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import { AppThemeProvider } from "./AppTheme";
import MainErrorBoundary from "./common/components/MainErrorBoundary";
import { AlertProvider } from "./common/context/AlertContext";
import { CollectiveProvider } from "./common/context/CollectiveContext";
import { setupAxiosDefaults } from "./config/AxiosConfig";
import { registerChartJsComponents } from "./config/ChartJsConfig";
import { AppRoutes } from "./config/routes/AppRoutes";

setupAxiosDefaults();
registerChartJsComponents();

function App() {
  return (
    <div className="App" style={{ height: "100%" }}>
      <MainErrorBoundary>
        <AppThemeProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <CssBaseline />
            <AlertProvider>
              <CollectiveProvider>
                <RouterProvider router={AppRoutes} />
              </CollectiveProvider>
            </AlertProvider>
          </LocalizationProvider>
        </AppThemeProvider>
      </MainErrorBoundary>
    </div>
  );
}

export default App;
