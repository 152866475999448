import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";
import { ProductInstanceSummary } from "../product/ProductInstanceSummary";
import ProductQuantityChip from "../product/ProductQuantityChip";

export default function ProductInstanceFilter({
  label,
  name,
  onChange,
  value,
  ...props
}) {
  // The product instance filter should not display if a specific product isn't already selected
  if (!value) {
    return null;
  }
  return (
    <Box>
      <ProductInstanceSummary
        data-testid="product-instance-filter"
        item={value}
        sx={{ backgroundColor: "rgba(0, 255, 0, 0.04)" }}
        summaryComponents={[
          ProductQuantityChip,
          ({ product }) => (
            <IconButton
              color="error"
              aria-label="remove product filter"
              onClick={() =>
                onChange({
                  target: {
                    name,
                    value: null,
                  },
                })
              }
            >
              <Close />
            </IconButton>
          ),
        ]}
      />
    </Box>
  );
}
