import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { Bar } from "react-chartjs-2";
import { PICKLINQ_COLOR_MAP } from "../../../common/components/form/FormFlowerColorPicker";
import FinancialReportingService from "../../../common/service/reporting/FinancialReportingService";
import { displayPrice } from "../../../common/util/DisplayUtil";
import ReportDownloadMenuButton from "../ReportDownloadMenuButton";

export default function SalesByColorReportDisplay({
  reportData,
  reportCriteria,
  reportTitle,
}) {
  const { startDate, endDate } = reportCriteria;

  reportData.sort((a, b) => b.totalSalePrice - a.totalSalePrice);

  const labels = reportData.map((data) => data.primaryColor);
  const reportOptions = {
    plugins: {
      title: {
        display: true,
        text:
          reportTitle +
          ": " +
          moment(startDate).format("MM/DD/YYYY") +
          " - " +
          moment(endDate).format("MM/DD/YYYY"),
      },
    },
    responsive: true,
    // standard vertical bar chart
    indexAxis: "y",
    maintainAspectRatio: false,
  };
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Total Items",
        data: reportData.map((data) => data.quantity),
        backgroundColor: reportData.map((data) => {
          if (data.primaryColor === "White") {
            return "#DDDDDD50";
          }
          return (
            (PICKLINQ_COLOR_MAP[data.primaryColor]?.hexValue || "#CCCCC") + "50"
          );
        }),
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        sx: { mt: 2 },
      }}
    >
      <Box style={{ height: `${labels.length * 42 + 50}px`, width: "100%" }}>
        <Bar options={reportOptions} data={data} />
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
          width: "100%",
        }}
      >
        <Typography variant="h5">Report Data</Typography>
        <ReportDownloadMenuButton
          reportData={reportData}
          getDownloadFileName={() =>
            `sales-report-by-color-${moment(startDate).format("MM-DD-YYYY")}-${moment(endDate).format("MM-DD-YYYY")}.csv`
          }
          getUnderlyingData={() =>
            FinancialReportingService.getSalesReportUnderlyingData(
              reportCriteria
            )
          }
        />
      </Box>

      <TableContainer component={Paper}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "800" }}>Color</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Quantity</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Total Sale Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.map((data, i) => (
              <TableRow key={i}>
                <TableCell>{data.primaryColor}</TableCell>
                <TableCell>{data.quantity}</TableCell>
                <TableCell>{displayPrice(data.totalSalePrice)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <b>Total</b>
              </TableCell>
              <TableCell>
                <b>
                  {reportData.reduce((acc, data) => data.quantity + acc, 0)}
                </b>
              </TableCell>
              <TableCell>
                <b>
                  {displayPrice(
                    reportData.reduce(
                      (acc, data) => data.totalSalePrice + acc,
                      0
                    )
                  )}
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
