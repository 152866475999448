import { Button, Container, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import LoadingOverlay from "../../../common/components/data-display/LoadingOverlay";
import { FormTextField } from "../../../common/components/form/FormTextField";
import { useAlerts } from "../../../common/context/AlertContext";
import TermsConditionsService from "../../../common/service/TermsConditionsService";

export default function EditTermsConditions({ typeOfTerms }) {
  const { addErrorAlert } = useAlerts();
  const navigate = useNavigate();
  if (!typeOfTerms) {
    navigate("/app/business-admin/manage-terms-conditions");
  }

  const [latestTermsConditions, setLatestTermsConditions] =
    React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setLoading(true);
    TermsConditionsService.getLatestTermsConditions(typeOfTerms)
      .then((response) => setLatestTermsConditions(response))
      .catch((error) => addErrorAlert("Error fetching terms conditions", error))
      .finally(() => setLoading(false));
  }, [addErrorAlert, typeOfTerms]);

  return (
    <Container sx={{ position: "relative" }}>
      {loading && <LoadingOverlay />}
      <Typography variant="h1" gutterBottom>
        Edit {typeOfTerms === "buyer" ? "Buyer" : "Supplier"} Terms & Conditions
      </Typography>
      {!loading && (
        <Formik
          initialValues={{
            type: typeOfTerms,
            content: latestTermsConditions?.content,
          }}
          validationSchema={Yup.object({
            content: Yup.string().required("Content is required"),
          })}
          onSubmit={async (values) => {
            return TermsConditionsService.createTermsConditions(values)
              .then(() => {
                navigate("/app/business-admin/manage-terms-conditions");
              })
              .catch((error) => {
                addErrorAlert("Error saving terms conditions", error);
              });
          }}
        >
          {() => (
            <Form>
              <Field
                component={FormTextField}
                name="content"
                label="Content"
                multiline
                rows={15}
              />
              <Button type="submit" variant="contained" sx={{ marginTop: 2 }}>
                Save
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
}
