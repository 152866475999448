import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { Bar } from "react-chartjs-2";
import { PICKLINQ_COLOR_MAP } from "../../../common/components/form/FormFlowerColorPicker";
import FinancialReportingService from "../../../common/service/reporting/FinancialReportingService";
import { displayPrice } from "../../../common/util/DisplayUtil";
import ReportDownloadMenuButton from "../ReportDownloadMenuButton";

export default function SalesByProductAndColorReportDisplay({
  reportData,
  reportCriteria,
  reportTitle,
}) {
  const { startDate, endDate } = reportCriteria;
  const labels = new Set();
  const colors = new Set();
  reportData.forEach((data) => {
    labels.add(data.productName);
    colors.add(data.primaryColor);
  });
  const labelsArray = Array.from(labels);

  const reportOptions = {
    plugins: {
      title: {
        display: true,
        text:
          reportTitle +
          ": " +
          moment(startDate).format("MM/DD/YYYY") +
          " - " +
          moment(endDate).format("MM/DD/YYYY"),
      },
    },
    responsive: true,
    indexAxis: "y",
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    maintainAspectRatio: false,
  };

  const datasets = Array.from(colors).map((color) => ({
    label: color + " Total Items",
    data: labelsArray.map((label) => {
      const productData = reportData.find(
        (data) => data.productName === label && data.primaryColor === color
      );
      return productData ? productData.quantity : 0;
    }),
    backgroundColor:
      color === "White"
        ? "#DDDDDD50"
        : (PICKLINQ_COLOR_MAP[color]?.hexValue || "#CCCCC") + "50",
    borderWidth: 1,
  }));

  const data = {
    labels: labelsArray,
    datasets: datasets,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        sx: { mt: 2 },
      }}
    >
      <Box style={{ height: `${labelsArray.length * 42}px`, width: "80%" }}>
        <Bar options={reportOptions} data={data} />
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
          width: "100%",
        }}
      >
        <Typography variant="h5">Report Data</Typography>
        <ReportDownloadMenuButton
          reportData={reportData}
          getDownloadFileName={() =>
            `sales-report-by-prod-color-${moment(startDate).format("MM-DD-YYYY")}-${moment(endDate).format("MM-DD-YYYY")}.csv`
          }
          getUnderlyingData={() =>
            FinancialReportingService.getSalesReportUnderlyingData(
              reportCriteria
            )
          }
        />
      </Box>

      <TableContainer component={Paper} sx={{ position: "relative" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "800" }}>Product & Color</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Quantity</TableCell>
              <TableCell sx={{ fontWeight: "800" }}>Total Sale Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.map((data, i) => (
              <TableRow key={i}>
                <TableCell>{`${data.productName} (${data.primaryColor})`}</TableCell>
                <TableCell>{data.quantity}</TableCell>
                <TableCell>{displayPrice(data.totalSalePrice)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <b>Total</b>
              </TableCell>
              <TableCell>
                <b>
                  {reportData.reduce((acc, data) => data.quantity + acc, 0)}
                </b>
              </TableCell>
              <TableCell>
                <b>
                  {displayPrice(
                    reportData.reduce(
                      (acc, data) => data.totalSalePrice + acc,
                      0
                    )
                  )}
                </b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
