import { Box } from "@mui/material";
import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import AutoSubmitOnChange from "../../../common/components/form/AutoSubmitOnChange";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import { FormDatePicker } from "../../../common/components/form/FormDatePicker";
import PickupLocationAutocomplete from "../../../common/components/form/PickupLocationAutocomplete";
import { useAlerts } from "../../../common/context/AlertContext";
import PresaleOrderService from "../../../common/service/PresaleOrderService";
import { usePresaleOrderContext } from "./PresaleOrderFormContext";

const validationSchema = Yup.object({
  saleDate: Yup.date()
    .typeError("Invalid date")
    .nullable()
    .required("Sale Date is required"),
  buyerBusienss: Yup.mixed().nullable(),
  pickupLocation: Yup.mixed().nullable(),
});

export default function PresaleBaseDetailsForm() {
  const { addErrorAlert } = useAlerts();
  const { presaleOrder, setPresaleOrder } = usePresaleOrderContext();
  const formikRef = React.useRef();

  React.useEffect(() => {
    if (presaleOrder?.id) {
      formikRef.current.resetForm({
        values: {
          saleDate: presaleOrder.saleDate,
          buyerBusiness: presaleOrder.buyerBusiness,
          pickupLocation: presaleOrder.pickupLocation,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presaleOrder?.id]);

  React.useEffect(() => {
    // If the sales log has changed elsewhere, reset the form to reflect the new sales log
    if (
      presaleOrder?.buyerBusiness?.id !==
        formikRef.current.values.buyerBusiness?.id ||
      presaleOrder?.pickupLocation?.id !==
        formikRef.current.values.pickupLocation?.id ||
      presaleOrder?.saleDate !== formikRef.current.values.saleDate
    ) {
      formikRef.current.resetForm({
        values: {
          saleDate: presaleOrder.saleDate,
          buyerBusiness: presaleOrder.buyerBusiness,
          pickupLocation: presaleOrder.pickupLocation,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presaleOrder]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        saleDate: presaleOrder?.saleDate,
        buyerBusiness: presaleOrder?.buyerBusiness,
        pickupLocation: presaleOrder?.pickupLocation,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        PresaleOrderService.updatePresaleOrder({ ...presaleOrder, ...values })
          .then(setPresaleOrder)
          .catch((e) => addErrorAlert("Error updating presale order", e));
      }}
    >
      {() => (
        <Form data-testid="presale-order-form">
          <AutoSubmitOnChange />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, m: 1 }}>
            <Box>
              <Field
                component={FormDatePicker}
                name="saleDate"
                label="Sale Date"
                sx={{ width: 165 }}
                size="small"
                disabled
              />
            </Box>
            <Box>
              <Field
                component={BusinessAutocomplete}
                name="buyerBusiness"
                label="Buyer"
                sx={{ width: 300 }}
                buyersOnly
                onlyUsersBusinesses
              />
            </Box>
            <Box>
              <Field
                component={PickupLocationAutocomplete}
                name="pickupLocation"
                label="Pickup Option"
                sx={{ width: 300 }}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
