import axios from "axios";

export const COLLECTIVES_BASE_URL =
  process.env.REACT_APP_API_PATH + "/collectives";
export const PUBLIC_COLLECTIVES_BASE_URL =
  process.env.REACT_APP_API_PATH + "/collectivesPublic";

const CollectiveService = {
  async getCurrentCollectiveInfo() {
    const response = await axios.get(PUBLIC_COLLECTIVES_BASE_URL + "/current");
    return response.data;
  },
  async updateCollective(collectiveId, collective) {
    const response = await axios.put(
      COLLECTIVES_BASE_URL + "/" + collectiveId,
      collective
    );
    return response.data;
  },
  async getCollectives(searchCriteria) {
    const response = await axios.post(
      COLLECTIVES_BASE_URL + "/search",
      searchCriteria
    );
    return response.data;
  },
  async getCollectiveById(collectiveId) {
    const response = await axios.get(COLLECTIVES_BASE_URL + "/" + collectiveId);
    return response.data;
  },
  async createCollective(collective) {
    const response = await axios.post(COLLECTIVES_BASE_URL, collective);
    return response.data;
  },
  async isSiteReachable(frontEndUrl) {
    try {
      // frontEndUrl is a string like "https://subdomain.picklinq.com" and is likely a cross origin request. Standard axios call won't work
      // Instead, we can use a simple fetch request
      await fetch(frontEndUrl, { mode: "no-cors" });

      return true;
    } catch (error) {
      return false;
    }
  },
};

export default CollectiveService;
