import { Storefront } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const CollectiveSummary = ({ item }) => {
  return (
    <Card sx={{ mb: 0.125 }} data-testid={"collective-summary"}>
      <CardActionArea
        component={Link}
        to={`/app/business-admin/manage-collectives/${item.id}`}
      >
        <CardContent
          sx={{ display: "flex", gap: 2, p: 1.5, alignItems: "center" }}
        >
          <Avatar sx={{ width: 56, height: 56 }} src={item.logoPath}>
            <Storefront />
          </Avatar>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1">
              <b>{item.name}</b>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {item.subdomain}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CollectiveSummary;
