import axios from "axios";

export const FINANCE_REPORTING_BASE_URL =
  process.env.REACT_APP_API_PATH + "/financialReporting";

const FinancialReportingService = {
  async getSupplierSnapshotTotals(searchCriteria) {
    const response = await axios.post(
      FINANCE_REPORTING_BASE_URL + "/supplier-snapshot-totals",
      searchCriteria
    );
    return response.data;
  },
  async getSalesReport(reportCriteria) {
    const response = await axios.post(
      FINANCE_REPORTING_BASE_URL + "/sales-report",
      reportCriteria
    );
    return response.data;
  },
  async getSalesReportUnderlyingData(reportCriteria) {
    const response = await axios.post(
      FINANCE_REPORTING_BASE_URL + "/sales-report/underlying-data",
      reportCriteria
    );
    // The response is a CSV file - download it
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    // get the file name from the Content-Disposition header
    let filename = "sales-data.csv";
    let header = response.headers.get("content-disposition");
    if (header) {
      filename = header.split("filename=")[1] || filename;
      filename = filename.replace(/['"]+/g, "");
    }
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  },
  async getSupplierSalesTotals(startDate, endDate) {
    const params = new URLSearchParams();
    if (startDate) {
      params.append("startDate", startDate);
    }
    if (endDate) {
      params.append("endDate", endDate);
    }
    const response = await axios.get(
      FINANCE_REPORTING_BASE_URL + "/supplier-totals",
      {
        params,
      }
    );
    return response.data;
  },
};

export default FinancialReportingService;
