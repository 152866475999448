import { Add, ListOutlined, ShoppingBag } from "@mui/icons-material";
import { Alert, Button, Container, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../../common/context/AuthContext";
import {
  COLLECTIVE_PROPERTY,
  useCollective,
} from "../../common/context/CollectiveContext";
import { isPresaleShoppingAvailable } from "../../common/util/DatePickerUtilFrontend";

export default function BuyerHome() {
  // If location.state.cancelCart is true, show an alert that their cart has been canceled
  const locationState = useLocation().state;
  const cartExpired = Boolean(locationState?.cartExpired);
  const { collectiveInfo, getCollectiveProperty } = useCollective();

  const isMarketClosed =
    getCollectiveProperty(COLLECTIVE_PROPERTY.IS_MARKET_CLOSED) === "closed";
  const marketClosedMessage = getCollectiveProperty(
    COLLECTIVE_PROPERTY.MARKET_CLOSED_MESSAGE
  );
  const isPresaleOpen =
    isPresaleShoppingAvailable(collectiveInfo) && !isMarketClosed;
  const { isMemberOfPlatinumBuyer } = useAuthContext();

  return (
    <Container
      style={{ textAlign: "center", marginTop: "20px" }}
      data-testid="buyer-home-container"
    >
      <Typography variant="h4" gutterBottom data-testid="buyer-home-title">
        Buyer Home
      </Typography>
      <Grid container gap={4} direction="column" alignItems={"center"}>
        <Grid>
          <Tooltip
            title={
              !isMemberOfPlatinumBuyer
                ? `Only platinum members of ${collectiveInfo.name} can shop the presale`
                : !isPresaleOpen
                  ? isMarketClosed
                    ? marketClosedMessage
                    : "The presale is closed"
                  : null
            }
          >
            <div>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="presale/shop"
                style={{ marginTop: "20px" }}
                data-testid="presale-button"
                startIcon={<ShoppingBag />}
                disabled={!isMemberOfPlatinumBuyer || !isPresaleOpen}
                size="large"
              >
                Shop Market Sales
              </Button>
            </div>
          </Tooltip>
          {cartExpired && (
            <Alert severity="warning" sx={{ mt: 1.5, maxWidth: 300 }}>
              Your cart expired - any items in your cart were returned.
            </Alert>
          )}
        </Grid>
        {isMarketClosed && (
          <Grid>
            <Alert
              variant="standard"
              severity="info"
              sx={{ whiteSpace: "pre-line" }}
            >
              {marketClosedMessage}
            </Alert>
          </Grid>
        )}
        <Grid>
          <Tooltip
            title={
              !isMemberOfPlatinumBuyer
                ? `Only platinum members of ${collectiveInfo.name} can make product requests`
                : null
            }
          >
            <div>
              <Button
                component={Link}
                variant="contained"
                data-testid="advanced-product-requests-button"
                color="primary"
                startIcon={<Add />}
                to="/app/buyer/product-requests/new"
                disabled={!isMemberOfPlatinumBuyer}
                size="large"
              >
                New Product Request
              </Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="view-orders"
            data-testid="view-orders-button"
            startIcon={<ListOutlined />}
            size="large"
          >
            View Orders
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
