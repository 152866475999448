import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults.jsx";
import CollectiveService from "../../../common/service/CollectiveService.js";
import CollectiveSummary from "./CollectiveSummary.jsx";
import ManageCollectiveSearchCriteria from "./ManageCollectiveSearchCriteria.jsx";

const ManageCollectives = () => {
  const defaultSearchCriteria = {
    searchText: "",
    page: 1,
    itemsPerPage: 10,
  };
  const [showSetupDialog, setShowSetupDialog] = React.useState(false);
  return (
    <Container sx={{ pt: 1.5 }}>
      <Grid container spacing={3} justifyContent="space-between">
        <Grid item>
          <Typography variant="h2" gutterBottom>
            Manage Collectives
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => setShowSetupDialog(true)}>
            Show setup instructions
          </Button>
        </Grid>
        <Grid item>
          <Link to="/app/business-admin/manage-collectives/new">
            <Button variant="contained" color="primary" startIcon={<Add />}>
              Add Collective
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Box sx={{ maxWidth: 600, marginLeft: "auto", marginRight: "auto" }}>
        <PaginatedSearchResults
          fetchSearchResults={CollectiveService.getCollectives}
          ResultDisplayComponent={CollectiveSummary}
          SearchCriteriaComponent={ManageCollectiveSearchCriteria}
          defaultSearchCriteria={defaultSearchCriteria}
        />
      </Box>
      <Dialog open={showSetupDialog} onClose={() => setShowSetupDialog(false)}>
        <DialogTitle>How to set up new Collective</DialogTitle>
        <DialogContent>
          <Typography>
            To set up a new collective, you need to create a new business and
            then add a new collective under that business.
          </Typography>
          <Typography>
            1. Ensure the business exists. Go to the{" "}
            <Link to="/app/business-admin/manage-businesses">Businesses</Link>{" "}
            page and either find or create a new business for this collective.
          </Typography>
          <Typography>
            2. Go to the{" "}
            <Link to="/app/business-admin/manage-users">Users</Link> page and
            ensure the owner of the business is added as a user. The business
            page should show this user listed as the owner.
          </Typography>
          <Typography>
            3. Go to the{" "}
            <Link to="/app/business-admin/manage-collectives/new">
              Add Collective
            </Link>{" "}
            page and enter the details, including selecting the business from
            step 1.
          </Typography>
          <Typography>
            4. The collective will be created and you can now manage the
            products and settings for the collective.
          </Typography>
          <Typography>
            5. Payment Methods and Pickup Options are not automatically created,
            so you will need to create these separately for the collective
            (currently not available in the UI).
          </Typography>
          <Typography>
            6. DNS Configuration: In both the Azure Portal and the DNS provider,
            you will need to add new records to point to the new collective's
            subdomain.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSetupDialog(false)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ManageCollectives;
