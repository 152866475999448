import { Alert, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import { ProductInstanceSummary } from "../../common/components/product/ProductInstanceSummary";
import { useAlerts } from "../../common/context/AlertContext";
import AdvancedRequestService from "../../common/service/AdvancedRequestService";
import { displayPrice } from "../../common/util/DisplayUtil";

export default function AdvancedRequestReview({
  advancedRequest,
  setFormikRef,
}) {
  const { addErrorAlert } = useAlerts();

  const errors = {};
  if (advancedRequest.requestProducts.length === 0) {
    errors.requestProducts = "Cannot submit an empty order";
  }

  const formik = useFormik({
    initialValues: { ...advancedRequest, fulfillmentStatus: "ORDER_PLACED" },
    initialErrors: errors,
    validationSchema: Yup.object().shape({
      requestProducts: Yup.array().test(
        "products-required",
        "Cannot submit an empty order",
        (value) => {
          return value.length > 0;
        }
      ),
    }),
    onSubmit: async (requestToSave) => {
      if (advancedRequest.fulfillmentStatus === "ORDER_PLACED") {
        // The advanced request is already placed, so we don't need to update it
        return Promise.resolve(advancedRequest);
      }
      return AdvancedRequestService.submitAdvancedRequest(
        advancedRequest.id
      ).catch((err) => addErrorAlert("Error saving changes", err));
    },
  });

  const formikRef = React.useRef(formik);

  React.useEffect(() => {
    setFormikRef(formikRef);
  }, [setFormikRef]);

  return (
    <Grid container columnSpacing={2} rowGap={2} alignItems="start">
      <Grid xs={12} md={6} container alignItems="start" justifyContent="start">
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 110, minWidth: 110 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Pickup Date:
            </Typography>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              {moment(advancedRequest.pickupDate).format("LL")}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 110, minWidth: 110 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Business:
            </Typography>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              {advancedRequest.buyerBusiness?.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 110, minWidth: 110 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Notes:
            </Typography>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1">{advancedRequest.notes}</Typography>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 110, minWidth: 110 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Quantity:
            </Typography>
          </Grid>
          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1" data-testid="cart-item-count">
              {advancedRequest.requestProducts?.reduce(
                (acc, item) => acc + item.quantityRequested,
                0
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} container flexWrap="nowrap">
          <Grid sx={{ width: 110, minWidth: 110 }}>
            <Typography variant="body1" sx={{ fontWeight: 800 }} gutterBottom>
              Total (est.):
            </Typography>
          </Grid>

          <Grid sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              {displayPrice(advancedRequest.estimatedTotal)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        data-testid="request-product-list"
        sx={{ mt: 1 }}
        xs={12}
        md={6}
      >
        <Grid xs={12}>
          <Typography variant="h5" gutterBottom>
            Requested Products
          </Typography>
        </Grid>
        {advancedRequest?.requestProducts.map((product, index) => (
          <Grid xs={12} key={index}>
            <ProductInstanceSummary productInstance={product} />
          </Grid>
        ))}
        {advancedRequest?.requestProducts.length === 0 && (
          <Grid xs={12}>
            <Alert severity="error">
              No products have been added to this request yet.
            </Alert>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
