import { Close, RemoveCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import BusinessDisplay from "../../common/components/data-display/BusinessDisplay";
import ProductAvailabilityIndicator from "../../common/components/product/ProductAvailabilityIndicator";
import { ProductInstanceSummary } from "../../common/components/product/ProductInstanceSummary";
import ProductQuantityWithFulfillmentChip from "../../common/components/product/ProductQuantityWithFulfillmentChip";
import { useAlerts } from "../../common/context/AlertContext";
import AdvancedRequestService from "../../common/service/AdvancedRequestService";
import SalesLogService from "../../common/service/SalesLogService";
import AdvancedRequestSummary from "../AdvancedRequests/AdvancedRequestSummary";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";
import ProductSalesCheckoutStatusForm from "./ProductSalesCheckoutStatusForm";

export default function AdvancedRequestsInSalesLog() {
  const [cachedAdvancedRequests, setCachedAdvancedRequests] = React.useState(
    {}
  );
  const [loadingDetails, setLoadingDetails] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedAdvancedRequest, setSelectedAdvancedRequest] =
    React.useState(null);
  const {
    salesLog,
    setSalesLog,
    pointOfSaleSearchRef,
    setIsShoppingInventory,
    setBackToAdvancedRequest,
    setBackToPresaleOrder,
  } = usePointOfSaleContext();
  const { addConfirmAlert, addSuccessAlert, addErrorAlert } = useAlerts();
  if (salesLog.advancedRequests.length === 0) {
    return null;
  }
  return (
    <>
      <Box data-testid="advanced-orders-in-sales-log" sx={{ ml: 2, mr: 2 }}>
        <Typography variant="h6">Product Requests</Typography>
        {salesLog.advancedRequests.map((advancedRequest, index) => (
          <Box
            key={advancedRequest.id}
            sx={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}
            gap={1}
          >
            <AdvancedRequestSummary
              adminView
              advancedRequest={advancedRequest}
              onClick={() => {
                const cachedRequest =
                  cachedAdvancedRequests[advancedRequest.id];
                if (cachedRequest) {
                  setSelectedAdvancedRequest(cachedRequest);
                  setDialogOpen(true);
                } else {
                  setSelectedAdvancedRequest(advancedRequest);
                  setDialogOpen(true);
                  setLoadingDetails(true);
                  AdvancedRequestService.getAdvancedRequestById(
                    advancedRequest.id
                  )
                    .then((fullAdvRequest) => {
                      setCachedAdvancedRequests({
                        ...cachedAdvancedRequests,
                        [advancedRequest.id]: fullAdvRequest,
                      });
                      setSelectedAdvancedRequest(fullAdvRequest);
                      setLoadingDetails(false);
                    })
                    .catch((e) => {
                      addErrorAlert("Error loading advanced request", e);
                      setLoadingDetails(false);
                    });
                }
              }}
            />
            <IconButton
              aria-label="Remove Presale Order"
              size="large"
              sx={{ height: "fit-content" }}
              onClick={() => {
                addConfirmAlert({
                  message: "Do you want to remove this product request?",
                  onConfirm: () =>
                    SalesLogService.removeAdvancedRequest(
                      salesLog.id,
                      advancedRequest.id
                    )
                      .then(() => {
                        salesLog.advancedRequests.splice(index, 1);
                        setSalesLog({
                          ...salesLog,
                        });
                        addSuccessAlert("Advanced request removed");
                      })
                      .catch((e) => {
                        addErrorAlert("Error removing advanced request", e);
                      }),
                  onReject: () => {},
                });
              }}
            >
              <RemoveCircle />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Advanced Request</DialogTitle>
        <DialogContent>
          <AdvancedRequestSummary
            advancedRequest={selectedAdvancedRequest}
            adminView
          />
          <Typography variant="h6" gutterBottom>
            Products
          </Typography>
          {loadingDetails && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Skeleton variant="rectangular" sx={{ height: 50, mb: 0.5 }} />
              <Skeleton variant="rectangular" sx={{ height: 50, mb: 0.5 }} />
              <Skeleton variant="rectangular" sx={{ height: 50, mb: 0.5 }} />
            </Box>
          )}
          {selectedAdvancedRequest?.requestProducts?.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {selectedAdvancedRequest.requestProducts.map((product) => (
                <Box key={product.id} sx={{ mb: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <ProductInstanceSummary
                      key={product.id}
                      productInstance={product}
                      summaryComponents={[
                        ProductQuantityWithFulfillmentChip,
                        ProductAvailabilityIndicator,
                      ]}
                      size="large"
                      onClick={() => {
                        setDialogOpen(false);
                        let primaryColor =
                          product.attributes.find(
                            (attr) => attr.attributeName === "PrimaryColor"
                          )?.attributeValue || "";
                        // Remove text after first space in primaryColor
                        primaryColor = primaryColor.split(" ")[0];
                        setBackToAdvancedRequest(() => () => {
                          // SOO gross. Set state can take a function, so use a function that returns a function
                          setDialogOpen(true);
                          setSelectedAdvancedRequest(selectedAdvancedRequest);
                          setIsShoppingInventory(false);
                        });
                        setBackToPresaleOrder(null);
                        pointOfSaleSearchRef?.current?.rerunSearch({
                          searchText: product.name,
                          colors: primaryColor ? [primaryColor] : [],
                          supplier: null,
                          page: 1,
                          specificProductInstance: null,
                        });
                        setIsShoppingInventory(true);
                      }}
                    />
                    <ProductSalesCheckoutStatusForm
                      product={product}
                      updateSalesCheckoutStatus={
                        AdvancedRequestService.updateSalesCheckoutStatus
                      }
                    />
                  </Box>
                  {selectedAdvancedRequest.fulfillments
                    .filter(
                      (fil) => fil.advancedRequestProductId === product.id
                    )
                    .map((fulfillment) => (
                      <Box
                        component={ButtonBase}
                        key={fulfillment.id}
                        sx={{
                          display: "flex",
                          width: "fit-content",
                          gap: 2,
                          alignItems: "center",
                          flexWrap: "nowrap",
                          border: "1px solid #E0E0E0",
                          borderRadius: 2,
                          p: 1,
                          ml: 2,
                        }}
                        onClick={() => {
                          let primaryColor =
                            product.attributes.find(
                              (attr) => attr.attributeName === "PrimaryColor"
                            )?.attributeValue || "";
                          // Remove text after first space in primaryColor
                          primaryColor = primaryColor.split(" ")[0];
                          setDialogOpen(false);
                          setBackToAdvancedRequest(() => () => {
                            // SOO gross. Set state can take a function, so use a function that returns a function
                            setDialogOpen(true);
                            setSelectedAdvancedRequest(selectedAdvancedRequest);
                            setIsShoppingInventory(false);
                          });
                          setBackToPresaleOrder(null);

                          pointOfSaleSearchRef?.current?.rerunSearch({
                            searchText: product.name,
                            colors: primaryColor ? [primaryColor] : [],
                            supplier: fulfillment.supplier,
                            page: 1,
                            specificProductInstance: null,
                          });
                          setIsShoppingInventory(true);
                        }}
                        data-testid="fulfillment-button"
                      >
                        <BusinessDisplay business={fulfillment.supplier} />
                        <Chip label={`× ${fulfillment.quantity}`} />
                      </Box>
                    ))}
                </Box>
              ))}
            </Box>
          )}
        </DialogContent>
        <Button
          onClick={() => setDialogOpen(false)}
          size="large"
          sx={{ position: "fixed", top: "12px", right: "12px" }}
          color="secondary"
          endIcon={<Close />}
          variant="contained"
        >
          Back to Sale
        </Button>
      </Dialog>
      <Divider />
    </>
  );
}
