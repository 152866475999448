import { Box, Typography } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { Form } from "react-router-dom";
import { useAlerts } from "../../context/AlertContext";
import LoadingOverlay from "./LoadingOverlay";

export default function ReportContainer({
  reportTitle,
  initialReportCriteria,
  fetchReportData,
  ReportCriteriaComponent,
  ReportComponent,
  validationSchema,
}) {
  const [reportCriteria, setReportCriteria] = React.useState(
    initialReportCriteria
  );
  const [reportData, setReportData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const { addErrorAlert } = useAlerts();
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    // If there is no criteria component, run the report once on load
    const runReportOnLoad = !ReportCriteriaComponent;

    if (runReportOnLoad) {
      submitRunReport(initialReportCriteria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialReportCriteria]);

  const submitRunReport = async (newCriteria) => {
    setReportCriteria(newCriteria);

    setReportData(null);
    setLoading(true);
    setError(null);
    try {
      const data = await fetchReportData(newCriteria);
      setReportData(data);
    } catch (e) {
      addErrorAlert("Failed to fetch report data", e);
      setError("There was an error loading the report");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {ReportCriteriaComponent && (
        <Formik
          initialValues={initialReportCriteria}
          onSubmit={submitRunReport}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <Form autoComplete="off">
              <ReportCriteriaComponent
                reportCriteria={reportCriteria}
                onSubmitReport={handleSubmit}
              />
            </Form>
          )}
        </Formik>
      )}
      <Box sx={{ minHeight: "400px", position: "relative" }}>
        {loading && <LoadingOverlay />}
        {error && (
          <Box
            sx={{
              height: "100%",
              textAlign: "center",
              verticalAlign: "center",
            }}
          >
            {error}
          </Box>
        )}

        {Boolean(reportData?.length > 0) && (
          <ReportComponent
            reportData={reportData}
            reportCriteria={reportCriteria}
            reportTitle={reportTitle}
          />
        )}
        {Boolean(reportData?.length === 0) && (
          <Typography textAlign={"center"} sx={{ mt: 3 }} variant="h6">
            No data found
          </Typography>
        )}
      </Box>
    </Box>
  );
}
