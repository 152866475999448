import { Chip, Tooltip } from "@mui/material";
import React from "react";

export default function ProductQuantityWithFulfillmentChip({ product }) {
  return (
    <Tooltip
      title={`${product.quantityFulfilled} items fulfilled of ${product.quantityRequested} requested`}
    >
      <Chip
        sx={{
          ml: 1,
          mr: 1,
          textAlign: "end",
          fontWeight: "bold",
          width: "80px",
        }}
        data-testid="product-instance-quantity"
        size="medium"
        label={`${product.quantityFulfilled} / ${product.quantityRequested}`}
        color={
          product.quantityFulfilled &&
          product.quantityFulfilled === product.quantityRequested
            ? "success"
            : product.quantityFulfilled === undefined
              ? "default"
              : "info"
        }
      />
    </Tooltip>
  );
}
