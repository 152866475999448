import { Outlet, createBrowserRouter } from "react-router-dom";
import { AgedOutProvider } from "../../common/context/AgedOutContext";
import AuthContextProvider from "../../common/context/AuthContext";
import AdminHome from "../../pages/Admin/AdminHome";
import AuditPayments from "../../pages/Admin/AuditPayments/AuditPayments";
import ManageAdvancedRequests from "../../pages/Admin/ManageAdvancedRequests/ManageAdvancedRequests";
import ManageAgedOutStock from "../../pages/Admin/ManageAgedOutStock/ManageAgedOutStock";
import EditBusiness from "../../pages/Admin/ManageBusinesses/EditBusiness";
import ManageBusinesses from "../../pages/Admin/ManageBusinesses/ManageBusinesses";
import EditMyCollective from "../../pages/Admin/ManageCollective/EditMyCollective";
import EditCollective from "../../pages/Admin/ManageCollectives/EditCollective";
import ManageCollectives from "../../pages/Admin/ManageCollectives/ManageCollectives";
import ViewCollective from "../../pages/Admin/ManageCollectives/ViewCollective";
import ManageInventory from "../../pages/Admin/ManageInventory.jsx/ManageInventory";
import ManagePresaleOrders from "../../pages/Admin/ManagePresaleOrders/ManagePresaleOrders";
import EditBaseProduct from "../../pages/Admin/ManageProducts/EditBaseProduct";
import ManageBaseProducts from "../../pages/Admin/ManageProducts/ManageBaseProducts";
import ManageSalesLogs from "../../pages/Admin/ManageSalesLogs/ManageSalesLogs";
import { SalesLogContextProvider } from "../../pages/Admin/ManageSalesLogs/SalesLogContext";
import SalesLogDetails from "../../pages/Admin/ManageSalesLogs/SalesLogDetails";
import EditTermsConditions from "../../pages/Admin/ManageTermsConditions/EditTermsConditions";
import ManageTermsConditions from "../../pages/Admin/ManageTermsConditions/ManageTermsConditions";
import ViewTermsConditions from "../../pages/Admin/ManageTermsConditions/ViewTermsConditions";
import EditUser from "../../pages/Admin/ManageUsers/EditUser";
import ManageUsers from "../../pages/Admin/ManageUsers/ManageUsers";
import AdvancedRequestDetails from "../../pages/AdvancedRequests/AdvancedRequestDetails";
import { AdvancedRequestFormContextProvider } from "../../pages/AdvancedRequests/AdvancedRequestFormContext";
import EditAdvancedRequest from "../../pages/AdvancedRequests/EditAdvancedRequest";
import ForgotPasswordPage from "../../pages/Auth/ForgotPasswordPage";
import LoginPage from "../../pages/Auth/LoginPage";
import ResetPasswordPage from "../../pages/Auth/ResetPasswordPage";
import SignUpPage from "../../pages/Auth/SignUpPage";
import VerifyEmail from "../../pages/Auth/VerifyEmail";
import AuthenticatedSiteLayout from "../../pages/AuthenticatedSiteLayout";
import BuyerHome from "../../pages/Buyer/BuyerHome";
import PresaleInventory from "../../pages/Buyer/Presale/PresaleInventory";
import PresaleOrderDetails from "../../pages/Buyer/Presale/PresaleOrderDetails";
import { PresaleOrderFormContextProvider } from "../../pages/Buyer/Presale/PresaleOrderFormContext";
import PresaleOrderReview from "../../pages/Buyer/Presale/PresaleOrderReview";
import ViewOrders from "../../pages/Buyer/ViewOrders";
import Dashboard from "../../pages/Dashboard/Dashboard";
import PageNotFound from "../../pages/General/PageNotFound";
import PointOfSale from "../../pages/PointOfSale/PointOfSale";
import { PointOfSaleContextProvider } from "../../pages/PointOfSale/PointOfSaleContextProvider";
import PointOfSalePayment from "../../pages/PointOfSale/PointOfSalePayment";
import LandingPage from "../../pages/Public/LandingPage";
import AgedOutReport from "../../pages/Reporting/AgedOutReport/AgedOutReport";
import ReportingHome from "../../pages/Reporting/ReportingHome";
import SalesReport from "../../pages/Reporting/SalesReport/SalesReport";
import SupplierSnapshot from "../../pages/Reporting/SupplierSnapshot";
import SupplierTotals from "../../pages/Reporting/SupplierTotals";
import BringToMarket from "../../pages/Supplier/BringToMarket/BringToMarket";
import AdvancedRequestSupplierView from "../../pages/Supplier/ProductRequests/AdvancedRequestSupplierView";
import SupplierHome from "../../pages/Supplier/SupplierHome";
import UploadInventory from "../../pages/Supplier/UploadInventory/UploadInventory";
import { AuthRoute } from "./AuthRoute";
import { NonAuthRoute } from "./NonAuthRoute";

export const AppRoutes = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthContextProvider>
        <Outlet />
      </AuthContextProvider>
    ),
    children: [
      { path: "/verify-email", element: <VerifyEmail /> },
      {
        path: "",
        element: (
          <NonAuthRoute>
            <LandingPage />
          </NonAuthRoute>
        ),
        children: [
          {
            path: "",
            element: <LoginPage />,
          },
          {
            path: "login",
            element: <LoginPage />,
          },
          {
            path: "sign-up",
            element: <SignUpPage />,
          },
          {
            path: "forgot-password",
            element: <ForgotPasswordPage />,
          },
          {
            path: "reset-password",
            element: <ResetPasswordPage />,
          },
        ],
      },
      {
        path: "/app",
        element: (
          <AuthRoute>
            <AuthenticatedSiteLayout />
          </AuthRoute>
        ),
        children: [
          {
            path: "",
            element: <Dashboard />,
          },

          {
            path: "supplier",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <SupplierHome />,
              },
              {
                path: "product-requests",
                element: <AdvancedRequestSupplierView />,
              },
              {
                path: "upload-inventory",
                element: <UploadInventory />,
              },
              {
                path: "bring-to-market",
                element: <BringToMarket />,
              },
            ],
          },
          {
            path: "buyer",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <BuyerHome />,
              },
              {
                path: "view-orders",
                element: <ViewOrders />,
              },
              {
                path: "presale",
                element: (
                  <PresaleOrderFormContextProvider>
                    <Outlet />
                  </PresaleOrderFormContextProvider>
                ),
                children: [
                  {
                    path: "shop",
                    element: <PresaleInventory />,
                  },
                  {
                    path: "review",
                    element: <PresaleOrderReview />,
                  },
                  {
                    path: "order/:orderId",
                    element: <PresaleOrderDetails />,
                  },
                ],
              },
              {
                path: "upload-inventory",
                element: <UploadInventory />,
              },
              {
                path: "bring-to-market",
                element: <BringToMarket />,
              },
              {
                path: "product-requests",
                element: (
                  <AdvancedRequestFormContextProvider adminView={false}>
                    <Outlet />
                  </AdvancedRequestFormContextProvider>
                ),
                children: [
                  {
                    path: "new",
                    element: <EditAdvancedRequest isNew />,
                  },
                  {
                    path: ":requestId",
                    element: <AdvancedRequestDetails />,
                  },
                  {
                    path: ":requestId/edit",
                    element: <EditAdvancedRequest />,
                  },
                ],
              },
            ],
          },
          {
            path: "point-of-sale",
            element: (
              <PointOfSaleContextProvider>
                <Outlet />
              </PointOfSaleContextProvider>
            ),
            children: [
              {
                path: "",
                element: <PointOfSale isNew />,
              },
              {
                path: ":salesLogId",
                element: <PointOfSale />,
              },
              {
                path: ":salesLogId/payment",
                element: <PointOfSalePayment />,
              },
            ],
          },
          {
            path: "business-admin/",
            children: [
              {
                element: <AdminHome />,
                path: "",
              },
              {
                path: "manage-businesses",
                element: <ManageBusinesses />,
              },
              {
                path: "manage-businesses/:businessId",
                element: <EditBusiness />,
              },
              {
                path: "manage-users",
                element: <ManageUsers />,
              },
              {
                path: "manage-requests",
                element: <ManageAdvancedRequests />,
              },
              {
                path: "manage-request",
                element: (
                  <AdvancedRequestFormContextProvider adminView>
                    <Outlet />
                  </AdvancedRequestFormContextProvider>
                ),
                children: [
                  {
                    path: ":requestId",
                    element: <AdvancedRequestDetails />,
                  },
                  {
                    path: ":requestId/edit",
                    element: <EditAdvancedRequest />,
                  },
                ],
              },
              {
                path: "manage-presale-orders",
                element: <ManagePresaleOrders />,
              },
              {
                path: "manage-users/:userId",
                element: <EditUser />,
              },
              {
                path: "manage-base-products",
                element: <ManageBaseProducts />,
              },
              {
                path: "manage-base-products/:baseProductId",
                element: <EditBaseProduct />,
              },
              {
                path: "manage-inventory",
                element: <ManageInventory />,
              },
              {
                path: "manage-inventory/add",
                element: <UploadInventory adminView />,
              },
              {
                path: "manage-aged-out-stock",
                element: (
                  <AgedOutProvider>
                    <ManageAgedOutStock />
                  </AgedOutProvider>
                ),
              },
              {
                path: "manage-sales-logs",
                element: <ManageSalesLogs />,
              },
              {
                path: "manage-sales-log/:salesLogId",
                element: (
                  <SalesLogContextProvider>
                    <SalesLogDetails adminView />
                  </SalesLogContextProvider>
                ),
              },
              {
                path: "audit-payments",
                element: <AuditPayments />,
              },
              {
                path: "manage-my-collective",
                element: <EditMyCollective />,
              },
              {
                path: "manage-terms-conditions/edit-buyer",
                element: <EditTermsConditions typeOfTerms={"buyer"} />,
              },
              {
                path: "manage-terms-conditions/edit-supplier",
                element: <EditTermsConditions typeOfTerms={"supplier"} />,
              },
              {
                path: "manage-terms-conditions/view/:termsConditionsId",
                element: <ViewTermsConditions />,
              },
              {
                path: "manage-terms-conditions",
                element: <ManageTermsConditions />,
              },
              {
                path: "manage-collectives",
                element: <ManageCollectives />,
              },
              {
                path: "manage-collectives/new",
                element: <EditCollective isNew />,
              },
              {
                path: "manage-collectives/:collectiveId",
                element: <ViewCollective />,
              },
              {
                path: "manage-collectives/:collectiveId/edit",
                element: <EditCollective />,
              },
            ],
          },
          {
            path: "reporting",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <ReportingHome />,
              },
              {
                path: "supplier-totals",
                element: <SupplierTotals />,
              },
              {
                path: "supplier-snapshot",
                element: <SupplierSnapshot />,
              },
              {
                path: "aged-out-report",
                element: <AgedOutReport />,
              },
              {
                path: "sales-report",
                element: <SalesReport />,
              },
            ],
          },
          { path: "*", element: <PageNotFound /> },
        ],
      },
    ],
  },
]);
