import { Paper } from "@mui/material";
import { Field } from "formik";
import React from "react";
import BusinessAutocomplete from "../../../common/components/form/BusinessAutocomplete";
import { FormDatePicker } from "../../../common/components/form/FormDatePicker";

export default function ManagePresaleOrderSearchCriteria() {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        gap: 2,
        flexWrap: "wrap",
      }}
      elevation={0}
    >
      <Field
        component={BusinessAutocomplete}
        name="buyerBusinesses"
        label="Business"
        multiple
        sx={{ maxWidth: 300, width: "100%" }}
      />
      <div>
        <Field
          component={FormDatePicker}
          name="startDateRange"
          label="Sale Date On or After"
          sx={{ maxWidth: 300, width: "100%" }}
          inputProps={{ "data-testid": "start-date-range" }}
          size="small"
        />
      </div>
      <div>
        <Field
          component={FormDatePicker}
          name="endDateRange"
          label="Sale Date Before"
          sx={{ maxWidth: 300, width: "100%" }}
          inputProps={{ "data-testid": "end-date-range" }}
          size="small"
        />
      </div>
    </Paper>
  );
}
