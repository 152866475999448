import {
  Box,
  Button,
  Container,
  Paper,
  Skeleton,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import PaginatedSearchResults from "../../common/components/data-display/PaginatedSearchResults";
import AutoSubmitOnChange from "../../common/components/form/AutoSubmitOnChange";
import BusinessAutocomplete from "../../common/components/form/BusinessAutocomplete";
import { FormDatePicker } from "../../common/components/form/FormDatePicker";
import { ProductInstanceSummary } from "../../common/components/product/ProductInstanceSummary";
import ProductQuantityChip from "../../common/components/product/ProductQuantityChip";
import { useAlerts } from "../../common/context/AlertContext";
import { useAuthContext } from "../../common/context/AuthContext";
import { useCollective } from "../../common/context/CollectiveContext";
import AgedOutService from "../../common/service/AgedOutService";
import FinancialReportingService from "../../common/service/reporting/FinancialReportingService";
import SalesLogService from "../../common/service/SalesLogService";
import StockLogService from "../../common/service/StockLogService";
import { displayPercentage, displayPrice } from "../../common/util/DisplayUtil";

function ProductQuantityAndPriceDisplay({ product }) {
  const priceCol = product.unitPrice ? "unitPrice" : "estimatedUnitPrice";
  return (
    <Box sx={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
      <Box sx={{ flexGrow: 1, textAlign: "end" }}>
        <Typography variant="body2">
          {displayPrice(product[priceCol])}/per
        </Typography>
      </Box>
      <ProductQuantityChip product={product} />
      <Typography>
        {displayPrice(product[priceCol] * product.quantity)}
      </Typography>
    </Box>
  );
}

function normalizeSearchCriteria(criteria) {
  const normalizedCriteria = { ...criteria };
  if (criteria.startDate) {
    normalizedCriteria.startDate = moment(criteria.startDate).format(
      "YYYY-MM-DD"
    );
  }
  if (criteria.endDate) {
    normalizedCriteria.endDate = moment(criteria.endDate).format("YYYY-MM-DD");
  }
  return normalizedCriteria;
}

const SupplierSnapshot = () => {
  const { collectiveInfo } = useCollective();
  const { addErrorAlert } = useAlerts();
  const [loadingTotals, setLoadingTotals] = React.useState(false);
  const [totals, setTotals] = React.useState(null);
  const { defaultSupplier, isOwnerOfCollective } = useAuthContext();
  const [searchCriteria, setSearchCriteria] = React.useState({
    supplier: defaultSupplier,
    startDate: moment().startOf("week").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    itemsPerPage: 10,
    page: 1,
  });
  const validationSchema = Yup.object().shape({
    supplier: Yup.mixed().required("Supplier is required"),
    startDate: Yup.date().required("Start date is required"),
    endDate: Yup.date().required("End date is required"),
  });
  const salesItemsSearchRef = React.useRef();
  const inventorySearchRef = React.useRef();
  const agedOutSearchRef = React.useRef();

  React.useEffect(() => {
    setLoadingTotals(true);
    if (!searchCriteria.supplier) {
      return;
    }
    FinancialReportingService.getSupplierSnapshotTotals(searchCriteria)
      .then(setTotals)
      .catch((e) => addErrorAlert("Error fetching supplier snapshot totals", e))
      .finally(() => setLoadingTotals(false));
  }, [addErrorAlert, searchCriteria]);
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Supplier Snapshot
      </Typography>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Formik
            initialValues={searchCriteria}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setSearchCriteria(values);
              setLoadingTotals(true);
              salesItemsSearchRef.current.rerunSearch(values);
              inventorySearchRef.current.rerunSearch(values);
              agedOutSearchRef.current.rerunSearch(values);
            }}
          >
            {({ setValues, values }) => (
              <Form>
                <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                  <AutoSubmitOnChange />
                  <Field
                    component={BusinessAutocomplete}
                    name="supplier"
                    label="Supplier"
                    sx={{ maxWidth: 300 }}
                    size="medium"
                    suppliersOnly
                    onlyUsersBusinesses={!isOwnerOfCollective}
                  />
                  <Field
                    component={FormDatePicker}
                    name="startDate"
                    label="Start Date"
                    sx={{ maxWidth: 180 }}
                  />
                  <Field
                    component={FormDatePicker}
                    name="endDate"
                    label="End Date"
                    sx={{ maxWidth: 180 }}
                  />
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() =>
                      setValues({
                        ...searchCriteria,
                        startDate: moment()
                          .startOf("year")
                          .format("YYYY-MM-DD"),
                        // Manual trigger to flag "dirty" so that the form will submit
                        manualTrigger: 1,
                        endDate: moment().format("YYYY-MM-DD"),
                      })
                    }
                  >
                    Year-to-date
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() =>
                      setValues({
                        ...searchCriteria,
                        startDate: moment()
                          .add(-30, "days")
                          .format("YYYY-MM-DD"),
                        // Manual trigger to flag "dirty" so that the form will submit
                        manualTrigger: 1,
                        endDate: moment().format("YYYY-MM-DD"),
                      })
                    }
                  >
                    Last 30 Days
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() =>
                      setValues({
                        ...searchCriteria,
                        startDate: moment()
                          .startOf("week")
                          .format("YYYY-MM-DD"),
                        // Manual trigger to flag "dirty" so that the form will submit
                        manualTrigger: 1,
                        endDate: moment().format("YYYY-MM-DD"),
                      })
                    }
                  >
                    This Week
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid xs={12} md={6}>
          <Paper elevation={4} sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
              Items Sold
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5" gutterBottom>
                {loadingTotals ? (
                  <Skeleton variant="text" />
                ) : (
                  Boolean(totals) &&
                  `Total Items Sold: ${totals.totalItemsSold}`
                )}
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ width: "fit-content" }}
              >
                {loadingTotals ? (
                  <Skeleton variant="text" />
                ) : (
                  Boolean(totals) && (
                    <>
                      Total Sales:{" "}
                      <strong>{displayPrice(totals.totalSales)}</strong>
                    </>
                  )
                )}
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom sx={{ textAlign: "end" }}>
              {loadingTotals ? (
                <Skeleton variant="text" />
              ) : (
                Boolean(totals) && (
                  <>
                    Sales Share:{" "}
                    {displayPercentage(totals.supplierSharePercent)} -{" "}
                    <strong>{displayPrice(totals.supplierShareTotal)}</strong>
                  </>
                )
              )}
            </Typography>
            {isOwnerOfCollective && (
              <Typography variant="h5" gutterBottom sx={{ textAlign: "end" }}>
                {loadingTotals ? (
                  <Skeleton variant="text" />
                ) : (
                  Boolean(totals) && (
                    <>
                      {collectiveInfo.name} Share:{" "}
                      {displayPercentage(1 - totals.supplierSharePercent)} -{" "}
                      <strong>
                        {displayPrice(
                          totals.totalSales - totals.supplierShareTotal
                        )}
                      </strong>
                    </>
                  )
                )}
              </Typography>
            )}
            <PaginatedSearchResults
              ref={salesItemsSearchRef}
              fetchSearchResults={SalesLogService.getItemsSoldForSupplier}
              defaultSearchCriteria={searchCriteria}
              ResultDisplayComponent={({ item }) => (
                <ProductInstanceSummary
                  item={item}
                  summaryComponents={[ProductQuantityAndPriceDisplay]}
                />
              )}
              navigateOnSearch={false}
              normalizeSearchCriteria={normalizeSearchCriteria}
              sx={{ maxHeight: "80vh", overflowY: "auto" }}
            />
          </Paper>
        </Grid>
        <Grid xs={12} md={6}>
          <Grid container spacing={3} flexDirection="column">
            <Grid xs={12}>
              <Paper elevation={4} sx={{ p: 2 }}>
                <Typography variant="h4">Uploaded Inventory</Typography>
                <Typography variant="h6">
                  {loadingTotals ? (
                    <Skeleton variant="text" />
                  ) : (
                    Boolean(totals) && (
                      <>
                        Items Stocked:{" "}
                        <strong>{totals.totalItemsUploaded}</strong>
                      </>
                    )
                  )}
                </Typography>
                <PaginatedSearchResults
                  ref={inventorySearchRef}
                  fetchSearchResults={StockLogService.getInventoryForAgeOut}
                  defaultSearchCriteria={searchCriteria}
                  ResultDisplayComponent={({ item }) => (
                    <ProductInstanceSummary
                      item={item}
                      summaryComponents={[ProductQuantityChip]}
                    />
                  )}
                  navigateOnSearch={false}
                  normalizeSearchCriteria={normalizeSearchCriteria}
                  sx={{ maxHeight: "40vh", overflowY: "auto" }}
                />
              </Paper>
            </Grid>
            <Grid xs={12}>
              <Paper elevation={4} sx={{ p: 2 }}>
                <Typography variant="h4">Aged Out</Typography>
                <Typography variant="h5">
                  {loadingTotals ? (
                    <Skeleton variant="text" />
                  ) : (
                    Boolean(totals) && (
                      <>
                        Items Aged Out:{" "}
                        <strong>{totals.totalItemsAgedOut}</strong>
                      </>
                    )
                  )}
                </Typography>
                <PaginatedSearchResults
                  ref={agedOutSearchRef}
                  fetchSearchResults={AgedOutService.searchAgedOutStock}
                  defaultSearchCriteria={searchCriteria}
                  ResultDisplayComponent={({ item }) => (
                    <ProductInstanceSummary
                      item={item}
                      summaryComponents={[ProductQuantityChip]}
                    />
                  )}
                  navigateOnSearch={false}
                  normalizeSearchCriteria={normalizeSearchCriteria}
                  sx={{ maxHeight: "40vh", overflowY: "auto" }}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SupplierSnapshot;
