import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useAlerts } from "../../context/AlertContext";
import TermsConditionsService from "../../service/TermsConditionsService";
import LoadingOverlay from "../data-display/LoadingOverlay";

export default function TermsConditionsSignDialog({
  onSign,
  onClose,
  open,
  typeOfTerms,
  ...props
}) {
  const { addErrorAlert } = useAlerts();
  const [latestTermsConditions, setLatestTermsConditions] =
    React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    setLoading(true);
    TermsConditionsService.getLatestTermsConditions(typeOfTerms)
      .then((response) => setLatestTermsConditions(response))
      .then(() => onClose())
      .catch((error) => addErrorAlert("Error fetching terms conditions", error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addErrorAlert, typeOfTerms]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="sign-terms-dialog"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Terms & Conditions</DialogTitle>

      <DialogContent sx={{ position: "relative" }}>
        {loading && <LoadingOverlay />}
        <Typography
          variant="body1"
          gutterBottom
          sx={{ whiteSpace: "pre-wrap" }}
        >
          {latestTermsConditions?.content}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-evenly", display: "flex" }}>
        <Button onClick={onClose} color="primary" data-testid="disagree-button">
          I Do Not Agree
        </Button>
        <Button
          type="submit"
          color="primary"
          data-testid="agree-button"
          onClick={() => {
            TermsConditionsService.signTermsConditions(latestTermsConditions.id)
              .then(onSign)
              .catch((e) =>
                addErrorAlert("Error signing terms and conditions", e)
              );
          }}
        >
          I Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
