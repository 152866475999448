import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { Bar } from "react-chartjs-2";
import FinancialReportingService from "../../../common/service/reporting/FinancialReportingService";
import { displayPrice } from "../../../common/util/DisplayUtil";
import ReportDownloadMenuButton from "../ReportDownloadMenuButton";

function formatWeekLabel(data) {
  return moment(data.weekStart).format("MMM D, YY");
}

export default function SalesByWeekReportDisplay({
  reportData,
  reportCriteria,
  reportTitle,
  displayDataTable = true,
}) {
  const { startDate, endDate } = reportCriteria;

  const labels = reportData.map((data) => formatWeekLabel(data));
  const reportOptions = {
    plugins: {
      title: {
        display: true,
        text:
          reportTitle +
          ": " +
          moment(startDate).format("MM/DD/YYYY") +
          " - " +
          moment(endDate).format("MM/DD/YYYY"),
      },
    },
    responsive: true,
    // standard vertical bar chart
    indexAxis: "x",
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Total Items",
        data: reportData.map((data) => data.totalSalePrice),
        backgroundColor: "rgba(99, 148, 255, 0.2)",
        borderColor: "rgba(99, 148, 255, 0.8)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Bar options={reportOptions} data={data} />
      {displayDataTable && (
        <>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Report Data</Typography>
            <ReportDownloadMenuButton
              reportData={reportData}
              getDownloadFileName={() =>
                `sales-report-by-week-${moment(startDate).format("MM-DD-YYYY")}-${moment(endDate).format("MM-DD-YYYY")}.csv`
              }
              getUnderlyingData={() =>
                FinancialReportingService.getSalesReportUnderlyingData(
                  reportCriteria
                )
              }
            />
          </Box>

          <TableContainer component={Paper}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "800" }}>Week</TableCell>
                  <TableCell sx={{ fontWeight: "800" }}>Quantity</TableCell>
                  <TableCell sx={{ fontWeight: "800" }}>
                    Total Sale Price
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.map((data, i) => (
                  <TableRow key={i}>
                    <TableCell>{formatWeekLabel(data)}</TableCell>
                    <TableCell>{data.quantity}</TableCell>
                    <TableCell>{displayPrice(data.totalSalePrice)}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <b>Total</b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {reportData.reduce((acc, data) => data.quantity + acc, 0)}
                    </b>
                  </TableCell>
                  <TableCell>
                    <b>
                      {displayPrice(
                        reportData.reduce(
                          (acc, data) => data.totalSalePrice + acc,
                          0
                        )
                      )}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
}
