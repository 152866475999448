import {
  Autocomplete,
  Avatar,
  CircularProgress,
  ListItemAvatar,
  ListItemText,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useAlerts } from "../../context/AlertContext";
import BusinessService from "../../service/BusinessService";
import DebouncedTextField from "./DebouncedTextField";

export default function BusinessAutocomplete({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  label,
  buyersOnly,
  suppliersOnly,
  onlyUsersBusinesses,
  autoSelectSingleOption = true,
  ignoreCollectiveFilter = false,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [businessList, setBusinessList] = useState();
  const { addErrorAlert } = useAlerts();

  React.useEffect(() => {
    refreshOptions(displayText, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshOptions(searchText, selectSingleOption) {
    setLoading(true);
    const searchCriteria = {
      searchText,
      page: 1,
      itemsPerPage: 20,
    };
    if (buyersOnly) {
      searchCriteria.isBuyer = true;
    }
    if (suppliersOnly) {
      searchCriteria.isSupplier = true;
    }
    if (onlyUsersBusinesses) {
      searchCriteria.onlyUsersBusinesses = true;
    }
    if (ignoreCollectiveFilter) {
      searchCriteria.ignoreCollectiveFilter = true;
    }

    BusinessService.getBusinesses(searchCriteria)
      .then((response) => {
        const options = response;
        if (
          value &&
          value.id &&
          !options.find((option) => option.id === value.id)
        ) {
          options.push(value);
        }
        if (
          autoSelectSingleOption &&
          selectSingleOption &&
          options.length === 1 &&
          !value
        ) {
          onChange({ target: { name, value: options[0] } });
          setDisplayText(options[0].name);
        }
        setBusinessList(options);
        setLoading(false);
      })
      .catch((error) => {
        addErrorAlert("Error fetching businesses", error);
      });
  }

  return (
    <>
      <Autocomplete
        label={label}
        size="small"
        fullWidth
        id={name}
        data-testid={name}
        loading={loading}
        inputValue={displayText}
        autoHighlight
        onInputChange={(e, newInputValue) => {
          setDisplayText(newInputValue);
        }}
        noOptionsText={
          displayText ? "No businesses found" : "Start typing to search"
        }
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        getOptionLabel={(option) => {
          if (option.id) {
            return option.name;
          } else {
            return option;
          }
        }}
        options={businessList || []}
        renderOption={(props, bizOption) => {
          if (bizOption.id) {
            return (
              <MenuItem {...props} key={bizOption.id}>
                <ListItemAvatar>
                  <Avatar src={bizOption.logoPath} />
                </ListItemAvatar>
                <ListItemText primary={bizOption.name} />
              </MenuItem>
            );
          }
        }}
        name={name}
        {...props}
        onChange={(e, newValue) => {
          if (!newValue) {
            refreshOptions("", false);
          }
          onChange({ target: { name, value: newValue } });
        }}
        value={value}
        renderInput={(params) => (
          <DebouncedTextField
            {...params}
            field={{
              name,
              value,
              onChange: (e) => {
                setDisplayText(e.target.value);
                refreshOptions(e.target.value, false);
              },
              onBlur,
            }}
            form={{ touched, errors }}
            setLoading={setLoading}
            label={label}
            required={props.required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
