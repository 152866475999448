import { ExitToApp, Forward } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { displayPrice } from "../../common/util/DisplayUtil";
import PointOfSaleCartItem from "./PointOfSaleCartItem";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";

export default function PointOfSaleCart({ ...props }) {
  const { salesLog } = usePointOfSaleContext();

  return (
    <Box
      data-testid={"presale-cart"}
      sx={{
        position: "relative",
        width: "100%",
        overflowY: "hidden",
        height: "100%",
        maxHeight: {
          xs: "100vh",
          // for sm and up, subtract the height of the app bar
          sm: `calc(100vh - 64px)`,
        },
        borderLeft: {
          xs: "none",
          sm: "1px solid",
        },
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ flexGrow: 1, width: "100%", height: 1, overflowY: "auto" }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ marginLeft: "auto", marginRight: "auto" }}
        >
          Cart
        </Typography>
        {salesLog.items.length === 0 ? (
          <Typography variant="body1" sx={{ p: 1 }}>
            Your cart is empty
          </Typography>
        ) : (
          salesLog.items.map((product, index) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              key={product.id}
            >
              <PointOfSaleCartItem item={product} />
            </Box>
          ))
        )}
        <Paper
          data-testid="estimated-total-container"
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
            borderTop: 1,
            borderColor: "divider",
            borderRadius: 0,
            p: 2,
            textAlign: "end",
            zIndex: 1,
            mt: 0.5,
          }}
          elevation={0}
        >
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" data-testid="cart-item-count">
              Quantity:{" "}
              {salesLog.items?.reduce((acc, item) => acc + item.quantity, 0)}
            </Typography>
            <Typography variant="h6" data-testid="cart-subtotal">
              Subtotal: {displayPrice(salesLog.subtotal)}
            </Typography>
          </Box>
        </Paper>
      </Box>
      {!salesLog.invoiceNumber && (
        <Button
          color="inherit"
          sx={{
            borderRadius: 0,
            fontSize: "110%",
            flex: "none",
          }}
          variant="contained"
          size="medium"
          fullWidth
          component={Link}
          to="/app/point-of-sale"
          endIcon={<ExitToApp />}
          disabled={!salesLog.items?.length}
        >
          Place Order on Hold
        </Button>
      )}
      <Button
        color="success"
        sx={{
          borderRadius: 0,
          fontSize: "120%",
          flex: "none",
        }}
        variant="contained"
        size="large"
        fullWidth
        component={Link}
        to="payment"
        endIcon={<Forward />}
        disabled={!salesLog.items?.length}
      >
        Continue to Payment
      </Button>
    </Box>
  );
}
