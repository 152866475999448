import { Box, Container, Typography } from "@mui/material";
import React from "react";
import PaginatedSearchResults from "../../common/components/data-display/PaginatedSearchResults";
import { useAuthContext } from "../../common/context/AuthContext";
import AdvancedRequestService from "../../common/service/AdvancedRequestService";
import PresaleOrderService from "../../common/service/PresaleOrderService";
import SalesLogService from "../../common/service/SalesLogService";
import SalesLogSummary from "../Admin/ManageSalesLogs/SalesLogSummary";
import AdvancedRequestSummary from "../AdvancedRequests/AdvancedRequestSummary";
import PresaleOrderSummary from "./Presale/PresaleOrderSummary";

export default function ViewOrders() {
  const { buyerBusinesses } = useAuthContext();
  const defaultPresaleSearchCriteria = {
    searchText: "",
    limitToUser: true,
    page: 1,
    itemsPerPage: 10,
  };

  const defaultAdvancedSearchCriteria = {
    searchText: "",
    page: 1,
    itemsPerPage: 10,
    businessIds: buyerBusinesses.map((business) => business.businessId),
    orderBy: "pickupDateDesc",
  };

  const defaultSalesSearchCriteria = {
    page: 1,
    itemsPerPage: 10,
    excludeEmptySales: true,
    buyerBusinessIds: buyerBusinesses.map((business) => business.businessId),
    status: "SUBMITTED",
  };

  return (
    <Container>
      <Typography variant="h1">Presale Orders</Typography>
      <Box sx={{ maxWidth: 800 }}>
        <PaginatedSearchResults
          fetchSearchResults={PresaleOrderService.getPresaleOrders}
          ResultDisplayComponent={PresaleOrderSummary}
          SearchCriteriaComponent={Box} // No search criteria ui for this page
          defaultSearchCriteria={defaultPresaleSearchCriteria}
          locationStateFieldName="presaleOrderCriteria"
        />
      </Box>
      <Typography variant="h1">Advanced Requests</Typography>
      <Box sx={{ maxWidth: 800 }}>
        <PaginatedSearchResults
          fetchSearchResults={AdvancedRequestService.getAdvancedRequests}
          ResultDisplayComponent={AdvancedRequestSummary}
          SearchCriteriaComponent={Box} // No search criteria ui for this page
          defaultSearchCriteria={defaultAdvancedSearchCriteria}
          locationStateFieldName="advancedOrderCriteria"
        />
      </Box>
      <Typography variant="h1">Purchase History</Typography>
      <Box sx={{ maxWidth: 800 }}>
        <PaginatedSearchResults
          fetchSearchResults={SalesLogService.getSalesLogs}
          defaultSearchCriteria={defaultSalesSearchCriteria}
          SearchCriteriaComponent={Box} // No search criteria ui for this page
          ResultDisplayComponent={SalesLogSummary}
        />
      </Box>
    </Container>
  );
}
