import { Box, Paper } from "@mui/material";
import { Field } from "formik";
import React from "react";
import FormSelect from "../../../common/components/form/FormSelect";

export default function TermsConditionsSearchCriteria() {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
      }}
      elevation={0}
    >
      <Box sx={{ width: 180 }}>
        <Field
          component={FormSelect}
          name="typeOfTerms"
          label="Type of Terms"
          sx={{ width: "100%" }}
          options={[
            { label: "-- Select an option --", value: "" },
            { label: "Supplier", value: "supplier" },
            { label: "Buyer", value: "buyer" },
          ]}
        />
      </Box>
    </Paper>
  );
}
