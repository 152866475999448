import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getIn } from "formik";
import moment from "moment";

export function FormDatePicker({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  ...props
}) {
  const standardizedValue = value
    ? typeof value === "string" // If the value is a string, convert it to a moment object
      ? moment(value)
      : value
    : null;
  return (
    <DatePicker
      data-testid={name}
      sx={{ width: "100%" }}
      size="small"
      {...props}
      name={name}
      onChange={(val) => {
        onChange({ target: { name, value: val } });
      }}
      slotProps={{
        field: {
          required: props.required,
          shouldRespectLeadingZeros: true,
        },
        textField: {
          required: props.required,
          id: name,
          fullWidth: props.fullWidth,
          size: props.size,
          variant: props.variant || "outlined",
          error:
            !!props.error ||
            (getIn(touched, name) && Boolean(getIn(errors, name))),
          helperText:
            props.error?.message || getIn(errors, name) || props.helperText,
          onBlur,
        },
      }}
      value={standardizedValue}
    />
  );
}
