import { Box, Container, Paper, Skeleton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingOverlay from "../../../common/components/data-display/LoadingOverlay";
import PaginatedSearchResults from "../../../common/components/data-display/PaginatedSearchResults";
import { useAlerts } from "../../../common/context/AlertContext";
import TermsConditionsService from "../../../common/service/TermsConditionsService";
import TermsConditionsLogSearchCriteria from "./TermsConditionsLogSearchCriteria";
import TermsConditionsLogSummary from "./TermsConditionsLogSummary";

export default function ViewTermsConditions() {
  const { termsConditionsId } = useParams();
  const { addErrorAlert } = useAlerts();
  const defaultSearchCriteria = {
    page: 1,
    itemsPerPage: 10,
    termsConditionsId: termsConditionsId,
  };
  const [loadingTerms, setLoadingTerms] = React.useState(true);
  const [termsConditions, setTermsConditions] = React.useState(null);
  useEffect(() => {
    TermsConditionsService.getTermsConditions(termsConditionsId)
      .then(setTermsConditions)
      .catch((error) => addErrorAlert("Error fetching terms conditions", error))
      .finally(() => setLoadingTerms(false));
  }, [addErrorAlert, termsConditionsId]);

  return (
    <Container>
      <Typography variant="h1" gutterBottom>
        View Terms & Conditions
      </Typography>
      <Box sx={{ position: "relative" }}>
        {loadingTerms && <LoadingOverlay />}
        <Paper sx={{ p: 2, mb: 2 }}>
          <Typography variant="h2" gutterBottom>
            {termsConditions ? (
              `${termsConditions?.type === "buyer" ? "Buyer" : "Supplier"} Terms &
            Conditions - v${termsConditions?.version}`
            ) : (
              <Skeleton />
            )}
          </Typography>
          <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
            {termsConditions?.content}
          </Typography>
        </Paper>
      </Box>
      <PaginatedSearchResults
        fetchSearchResults={
          TermsConditionsService.getTermsConditionsSignatories
        }
        defaultSearchCriteria={defaultSearchCriteria}
        ResultDisplayComponent={TermsConditionsLogSummary}
        SearchCriteriaComponent={TermsConditionsLogSearchCriteria}
      />
    </Container>
  );
}
