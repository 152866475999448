import { Download, MoreHoriz, TableRows } from "@mui/icons-material";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { CSVLink } from "react-csv";

export default function ReportDownloadMenuButton({
  reportData,
  getUnderlyingData,
  getDownloadFileName = () => "report.csv",
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        startIcon={<MoreHoriz />}
      >
        Download
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <CSVLink
          variant="contained"
          data={reportData}
          filename={getDownloadFileName()}
          style={{ textDecoration: "none" }}
        >
          <MenuItem sx={{ textDecoration: "none" }}>
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  textDecoration: "none",
                  // Use the theme color for the text color
                  color: "text.primary",
                },
              }}
              primary="Download Report"
            />
          </MenuItem>
        </CSVLink>
        <MenuItem
          onClick={() => {
            getUnderlyingData();
          }}
        >
          <ListItemIcon>
            <TableRows />
          </ListItemIcon>
          <ListItemText primary="Download Underlying Data" />
        </MenuItem>
      </Menu>
    </>
  );
}
