import {
  AttachMoney,
  Business,
  Description,
  History,
  Inventory,
  ListAlt,
  ManageAccounts,
  Settings,
  ShoppingBag,
} from "@mui/icons-material";
import {
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../common/context/AuthContext";
import { useCollective } from "../../common/context/CollectiveContext";
import { PermissionService } from "../../common/service/PermissionService";

const AdminHome = () => {
  const { collectiveInfo } = useCollective();
  const { isOwnerOfCollective } = useAuthContext();
  return (
    <Container sx={{ pt: 1.5 }}>
      <Typography variant="h2" gutterBottom>
        Admin Home
      </Typography>
      <Grid container spacing={3}>
        <Grid xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Collective Administration
          </Typography>
          <List size="large">
            <ListItemButton component={Link} to="manage-requests">
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText primary="Product Requests" />
            </ListItemButton>
            <ListItemButton component={Link} to="manage-presale-orders">
              <ListItemIcon>
                <ShoppingBag />
              </ListItemIcon>
              <ListItemText primary="Presale Orders" />
            </ListItemButton>
            <ListItemButton component={Link} to="manage-sales-logs">
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText primary="Sales Logs" />
            </ListItemButton>
            <ListItemButton component={Link} to="manage-inventory">
              <ListItemIcon>
                <Inventory />
              </ListItemIcon>
              <ListItemText primary="Manage Inventory" />
            </ListItemButton>
            <ListItemButton component={Link} to="manage-aged-out-stock">
              <ListItemIcon>
                <History />
              </ListItemIcon>
              <ListItemText primary="Manage Aged Out Stock" />
            </ListItemButton>
            <ListItemButton component={Link} to="audit-payments">
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>
              <ListItemText primary="Audit Payments" />
            </ListItemButton>
          </List>
        </Grid>
        <Grid xs={12} sm={6}>
          <Typography variant="h4" gutterBottom>
            Site Administration
          </Typography>
          <List size="large">
            <ListItemButton component={Link} to="manage-businesses">
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText primary="Manage Businesses" />
            </ListItemButton>
            <ListItemButton component={Link} to="manage-users">
              <ListItemIcon>
                <ManageAccounts />
              </ListItemIcon>
              <ListItemText primary="Manage Users" />
            </ListItemButton>
            <ListItemButton component={Link} to="manage-base-products">
              <ListItemIcon>
                <ListAlt />
              </ListItemIcon>
              <ListItemText primary="Manage Products" />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="manage-terms-conditions"
              data-testid="manage-terms-conditions-link"
            >
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ListItemText primary="Manage Terms & Conditions" />
            </ListItemButton>
            {isOwnerOfCollective && (
              <ListItemButton component={Link} to="manage-my-collective">
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Manage My Collective" />
              </ListItemButton>
            )}
          </List>
        </Grid>
        {isOwnerOfCollective &&
          PermissionService.isAdminCollective(collectiveInfo) && (
            <Grid xs={12} sm={6}>
              <Typography variant="h4" gutterBottom>
                Manage Collectives
              </Typography>
              <List size="large">
                <ListItemButton
                  component={Link}
                  to="manage-collectives"
                  data-testid="manage-collectives-link"
                >
                  <ListItemIcon>
                    <Business />
                  </ListItemIcon>
                  <ListItemText primary="Manage Collectives" />
                </ListItemButton>
              </List>
            </Grid>
          )}
      </Grid>
    </Container>
  );
};

export default AdminHome;
