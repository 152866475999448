import { Box, Paper } from "@mui/material";
import { Field } from "formik";
import React from "react";
import UserAutocomplete from "../../../common/components/form/UserAutocomplete";

export default function TermsConditionsLogSearchCriteria() {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
      }}
      elevation={0}
    >
      <Box sx={{ width: 280 }}>
        <Field
          component={UserAutocomplete}
          name="user"
          label="User"
          sx={{ width: "100%" }}
        />
      </Box>
    </Paper>
  );
}
