import { AddShoppingCart, Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import PaginatedSearchResults from "../../common/components/data-display/PaginatedSearchResults";
import { useAlerts } from "../../common/context/AlertContext";
import PresaleOrderService from "../../common/service/PresaleOrderService";
import SalesLogService from "../../common/service/SalesLogService";
import ManagePresaleOrderSearchCriteria from "../Admin/ManagePresaleOrders/ManagePresaleOrdersSearchCriteria";
import { AdminPresaleOrderSummary } from "../Buyer/Presale/PresaleOrderSummary";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";

export default function RecentPresaleOrderSearch() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { addErrorAlert } = useAlerts();
  const { salesLog, setSalesLog } = usePointOfSaleContext();
  const defaultSearchCriteria = {
    searchText: "",
    page: 1,
    itemsPerPage: 25,
    startDateRange: null,
    endDateRange: null,
    buyerBusinesses: [],
  };
  if (salesLog.buyerBusiness) {
    defaultSearchCriteria.buyerBusinesses.push(salesLog.buyerBusiness);
  }

  React.useEffect(() => {}, []);

  return (
    <Box data-testid="recent-presale-order-search">
      <Button
        onClick={() => setDialogOpen(true)}
        size="medium"
        variant="outlined"
        startIcon={<AddShoppingCart />}
      >
        Presale Order
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Select a recent presale order</DialogTitle>
        <DialogContent>
          {dialogOpen && (
            <PaginatedSearchResults
              navigateOnSearch={false}
              fetchSearchResults={PresaleOrderService.getPresaleOrders}
              defaultSearchCriteria={defaultSearchCriteria}
              SearchCriteriaComponent={ManagePresaleOrderSearchCriteria}
              ResultDisplayComponent={AdminPresaleOrderSummary}
              onClickSearchResult={(item) => {
                SalesLogService.addPresaleOrder(salesLog.id, item.id)
                  .then((updatedSalesLog) => {
                    setSalesLog(updatedSalesLog);
                  })
                  .catch((error) => {
                    addErrorAlert("Error adding product request", error);
                  })
                  .finally(() => setDialogOpen(false));
              }}
              validationSchema={Yup.object().shape({
                searchText: Yup.string(),
                startDateRange: Yup.date()
                  .nullable()
                  .when("endDateRange", {
                    is: (endDateRange) => endDateRange,
                    then: () =>
                      Yup.date().max(
                        Yup.ref("endDateRange"),
                        "Start date must be before end date"
                      ),
                  }),
                endDateRange: Yup.date().nullable(),
                buyerBusinesses: Yup.array(),
              })}
              normalizeSearchCriteria={(criteria) => {
                const cleanedCriteria = Object.fromEntries(
                  Object.entries(criteria).filter(([_, v]) => v)
                );
                if (
                  cleanedCriteria.startDateRange &&
                  typeof cleanedCriteria.startDateRange !== "string"
                ) {
                  cleanedCriteria.startDateRange =
                    cleanedCriteria.startDateRange.toISOString();
                }
                if (
                  cleanedCriteria.endDateRange &&
                  typeof cleanedCriteria.endDateRange !== "string"
                ) {
                  cleanedCriteria.endDateRange =
                    cleanedCriteria.endDateRange.toISOString();
                }
                return cleanedCriteria;
              }}
            />
          )}
          <Button
            onClick={() => setDialogOpen(false)}
            size="large"
            sx={{ position: "fixed", top: "12px", right: "12px" }}
            color="secondary"
            endIcon={<Close />}
            variant="contained"
          >
            Back to Sale
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
