import axios from "axios";

export const TERMS_BASE_URL =
  process.env.REACT_APP_API_PATH + "/termsConditions";

const TermsConditionsService = {
  async getTermsConditionsList(searchCriteria) {
    const response = await axios.post(
      TERMS_BASE_URL + "/search",
      searchCriteria
    );
    return response.data;
  },
  async getLatestTermsConditions(typeOfTerms) {
    const response = await axios.get(TERMS_BASE_URL, {
      params: new URLSearchParams({ typeOfTerms }),
    });
    return response.data;
  },
  async signTermsConditions(termsConditionsId) {
    const response = await axios.post(TERMS_BASE_URL + "/sign", {
      termsConditionsId,
    });
    return response.data;
  },
  async createTermsConditions(termsConditions) {
    const response = await axios.post(TERMS_BASE_URL, termsConditions);
    return response.data;
  },
  async getTermsConditions(termsConditionsId) {
    const response = await axios.get(TERMS_BASE_URL + "/" + termsConditionsId);
    return response.data;
  },
  async getTermsConditionsSignatories(searchCriteria) {
    const response = await axios.post(
      TERMS_BASE_URL + "/signatories",
      searchCriteria
    );
    return response.data;
  },
};

export default TermsConditionsService;
