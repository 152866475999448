import { FormTextField } from "./FormTextField";

export function FormQuantityField({ min = 1, ...props }) {
  return (
    <FormTextField
      type="number"
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        min,
        step: 1,
      }}
      {...props}
    />
  );
}
