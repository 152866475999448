import { Chip } from "@mui/material";
import React from "react";

export default function ProductQuantityAvailableChip({ product }) {
  return (
    <Chip
      sx={{
        ml: 1,
        textAlign: "end",
        fontWeight: "bold",
        width: "70px",
      }}
      data-testid="product-instance-quantity-available"
      size="medium"
      label={`× ${product.quantityAvailable}`}
      color={product.quantityAvailable === 0 ? "error" : "default"}
    />
  );
}
