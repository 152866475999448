import { Close, RemoveCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import { ProductInstanceSummary } from "../../common/components/product/ProductInstanceSummary";
import ProductQuantityChip from "../../common/components/product/ProductQuantityChip";
import { useAlerts } from "../../common/context/AlertContext";
import PresaleOrderService from "../../common/service/PresaleOrderService";
import SalesLogService from "../../common/service/SalesLogService";
import { AdminPresaleOrderSummary } from "../Buyer/Presale/PresaleOrderSummary";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";
import ProductSalesCheckoutStatusForm from "./ProductSalesCheckoutStatusForm";

export default function PresaleOrdersInSalesLog() {
  const [cachedPresaleOrders, setCachedPresaleOrders] = React.useState({});
  const [loadingDetails, setLoadingDetails] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedPresaleOrder, setSelectedPresaleOrder] = React.useState(null);
  const {
    salesLog,
    setSalesLog,
    pointOfSaleSearchRef,
    setIsShoppingInventory,
    setBackToPresaleOrder,
    setBackToAdvancedRequest,
  } = usePointOfSaleContext();
  const { addConfirmAlert, addSuccessAlert, addErrorAlert } = useAlerts();
  if (salesLog.presaleOrders.length === 0) {
    return null;
  }
  return (
    <>
      <Box data-testid="presale-orders-in-sales-log" sx={{ ml: 2, mr: 2 }}>
        <Typography variant="h6">Presale Orders</Typography>
        {salesLog.presaleOrders.map((presaleOrder, index) => (
          <Box
            key={presaleOrder.id}
            sx={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}
            gap={1}
          >
            <AdminPresaleOrderSummary
              presaleOrder={presaleOrder}
              onClick={() => {
                const cachedRequest = cachedPresaleOrders[presaleOrder.id];
                if (cachedRequest) {
                  setSelectedPresaleOrder(cachedRequest);
                  setDialogOpen(true);
                } else {
                  setSelectedPresaleOrder(presaleOrder);
                  setDialogOpen(true);
                  setLoadingDetails(true);
                  PresaleOrderService.getPresaleOrder(presaleOrder.id)
                    .then((fullPresaleOrder) => {
                      setCachedPresaleOrders({
                        ...cachedPresaleOrders,
                        [presaleOrder.id]: fullPresaleOrder,
                      });
                      setSelectedPresaleOrder(fullPresaleOrder);
                      setLoadingDetails(false);
                    })
                    .catch((e) => {
                      addErrorAlert("Error loading presale order", e);
                      setLoadingDetails(false);
                    });
                }
              }}
            />
            <IconButton
              aria-label="Remove Presale Order"
              size="large"
              sx={{ height: "fit-content" }}
              onClick={() => {
                addConfirmAlert({
                  message: "Do you want to remove this presale order?",
                  onConfirm: () =>
                    SalesLogService.removePresaleOrder(
                      salesLog.id,
                      presaleOrder.id
                    )
                      .then(() => {
                        salesLog.presaleOrders.splice(index, 1);
                        setSalesLog({
                          ...salesLog,
                        });
                        addSuccessAlert("Presale order removed");
                      })
                      .catch((e) => {
                        addErrorAlert("Error removing presale order", e);
                      }),
                  onReject: () => {},
                });
              }}
            >
              <RemoveCircle />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Presale Order</DialogTitle>
        <DialogContent>
          <AdminPresaleOrderSummary presaleOrder={selectedPresaleOrder} />
          <Typography variant="h6" gutterBottom>
            Products
          </Typography>
          {loadingDetails && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Skeleton variant="rectangular" sx={{ height: 50, mb: 0.5 }} />
              <Skeleton variant="rectangular" sx={{ height: 50, mb: 0.5 }} />
              <Skeleton variant="rectangular" sx={{ height: 50, mb: 0.5 }} />
            </Box>
          )}
          {selectedPresaleOrder?.orderProducts?.length > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {selectedPresaleOrder.orderProducts.map((product, idx) => (
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                  key={idx}
                  alignItems={"center"}
                >
                  <ProductInstanceSummary
                    productInstance={product}
                    summaryComponents={[ProductQuantityChip]}
                    size="large"
                    onClick={() => {
                      setDialogOpen(false);
                      setBackToPresaleOrder(() => () => {
                        // SOO gross. Set state can take a function, so use a function that returns a function
                        setDialogOpen(true);
                        setSelectedPresaleOrder(selectedPresaleOrder);
                        setIsShoppingInventory(false);
                      });
                      setBackToAdvancedRequest(null);
                      setIsShoppingInventory(true);
                      pointOfSaleSearchRef?.current?.rerunSearch({
                        searchText: "",
                        colors: [],
                        supplier: null,
                        page: 1,
                        specificProductInstance: product,
                      });
                    }}
                  />
                  <ProductSalesCheckoutStatusForm
                    product={product}
                    updateSalesCheckoutStatus={
                      PresaleOrderService.updateSalesCheckoutStatus
                    }
                  />
                </Box>
              ))}
            </Box>
          )}
        </DialogContent>
        <Button
          onClick={() => setDialogOpen(false)}
          size="large"
          sx={{ position: "fixed", top: "12px", right: "12px" }}
          color="secondary"
          endIcon={<Close />}
          variant="contained"
        >
          Back to Sale
        </Button>
      </Dialog>
      <Divider />
    </>
  );
}
