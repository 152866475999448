import { Paper } from "@mui/material";
import { Field } from "formik";
import React from "react";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField";

const ManageCollectiveSearchCriteria = () => {
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        gap: 1.5,
        flexWrap: "wrap",
      }}
      elevation={0}
    >
      <Field
        component={DebouncedTextField}
        label="Search..."
        name="searchText"
        sx={{ maxWidth: 300 }}
      />
    </Paper>
  );
};

export default ManageCollectiveSearchCriteria;
