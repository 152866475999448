import { ArrowBack } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";
import PaginatedSearchResults from "../../common/components/data-display/PaginatedSearchResults";
import { useCollective } from "../../common/context/CollectiveContext";
import BaseProductService from "../../common/service/BaseProductService";
import { usePointOfSaleContext } from "./PointOfSaleContextProvider";
import PointOfSaleInventoryProductSummary from "./PointOfSaleInventoryProductSummary";
import PointOfSaleOtherItemsSearchCriteria from "./PointOfSaleOtherItemsSearchCriteria";

export default function PointOfSaleOtherItemsSearch() {
  const { collectiveInfo } = useCollective();
  const { setItemToAdd, setIsShoppingOther } = usePointOfSaleContext();
  const defaultSearchCriteria = {
    searchText: "",
    supplier: null,
    activeStatus: "active",
    category: null,
    page: 1,
    itemsPerPage: 24,
  };

  return (
    <Box data-testid="sales-inventory" flexDirection="column">
      <Button
        size="large"
        onClick={() => setIsShoppingOther(false)}
        startIcon={<ArrowBack />}
        variant="contained"
        sx={{ mt: 1, ml: 1 }}
      >
        Return to Invoice
      </Button>
      <PaginatedSearchResults
        navigateOnSearch={false}
        SearchCriteriaComponent={PointOfSaleOtherItemsSearchCriteria}
        fetchSearchResults={BaseProductService.getBaseProducts}
        defaultSearchCriteria={defaultSearchCriteria}
        ResultDisplayComponent={PointOfSaleInventoryProductSummary}
        SearchResultsProps={{
          sx: {
            display: "flex",
            gap: 2.5,
            flexWrap: "wrap",
            justifyContent: "space-around",
          },
        }}
        PaginationProps={{
          itemsPerPageOptions: [
            { label: "24", value: 24 },
            { label: "48", value: 48 },
            { label: "100", value: 100 },
          ],
        }}
        onClickSearchResult={(item) => {
          // Transform base product to look more like a product instance
          setItemToAdd({
            ...item,
            isBaseProduct: true,
            supplier: {
              id: collectiveInfo.businessId,
              name: collectiveInfo.name,
              logoPath: collectiveInfo.logoPath,
            },
          });
        }}
      />
    </Box>
  );
}
