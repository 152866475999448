import { CheckCircle, Error } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Link as LinkText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BusinessDisplay from "../../../common/components/data-display/BusinessDisplay";
import CollectiveService from "../../../common/service/CollectiveService";

const ViewCollective = () => {
  const { collectiveId } = useParams();
  const [collective, setCollective] = useState(null);
  const [isSiteReachable, setIsSiteReachable] = useState(undefined);

  useEffect(() => {
    const fetchCollective = async () => {
      const data = await CollectiveService.getCollectiveById(collectiveId);
      const business = data.businessId
        ? {
            id: data.businessId,
            name: data.businessName,
            logoPath: data.logoPath,
          }
        : null;
      setCollective({ ...data, business });
    };

    fetchCollective();
  }, [collectiveId]);

  useEffect(() => {
    const checkSiteStatus = async () => {
      const isReachable = await CollectiveService.isSiteReachable(
        collective.frontEndUrl
      );
      setIsSiteReachable(isReachable);
    };
    if (collective) {
      checkSiteStatus();
    }
  }, [collective]);

  if (!collective) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container sx={{ pt: 1.5 }}>
      <Typography variant="h2" gutterBottom>
        {collective.name}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Subdomain: {collective.subdomain} (
        <LinkText href={collective.frontEndUrl} target="_blank">
          {collective.frontEndUrl}
        </LinkText>
        )
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          Site Status:{" "}
          {isSiteReachable === undefined ? (
            <Typography>Loading...</Typography>
          ) : isSiteReachable ? (
            <>
              <CheckCircle color="success" />
              <Typography>Site is reachable</Typography>
            </>
          ) : (
            <>
              <Error color="error" />
              <Typography>Site is not reachable</Typography>
            </>
          )}
        </Box>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Primary Color:{" "}
        <span
          style={{
            height: 14,
            width: 14,
            borderRadius: "2px",
            backgroundColor: collective.primaryColor,
            marginRight: 1,
            display: "inline-block",
          }}
        />
        {collective.primaryColor}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Secondary Color:{" "}
        <span
          style={{
            height: 14,
            width: 14,
            borderRadius: "2px",
            backgroundColor: collective.secondaryColor,
            marginRight: 1,
            display: "inline-block",
          }}
        />
        {collective.secondaryColor}
      </Typography>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <BusinessDisplay business={collective.business} isLink />
      </Box>
      <Button
        component={Link}
        to={`/app/business-admin/manage-collectives/${collectiveId}/edit`}
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
      >
        Edit Collective
      </Button>
    </Container>
  );
};

export default ViewCollective;
