import { Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../common/context/AuthContext";
import { displayName } from "../../common/util/DisplayUtil";

const Dashboard = () => {
  const {
    currentUser,
    isMemberOfAnyBuyer,
    isMemberOfAnySupplier,
    isOwnerOfCollective,
  } = useAuthContext();
  const navigate = useNavigate();

  if (isMemberOfAnyBuyer && !isMemberOfAnySupplier && !isOwnerOfCollective) {
    navigate("/app/buyer");
  }

  return (
    <Container>
      <Typography variant="h1" gutterBottom>
        Welcome, {displayName(currentUser.firstName, currentUser.lastName)}
      </Typography>
      <Typography variant="body1">
        This is the dashboard for the inventory system.
      </Typography>
    </Container>
  );
};

export default Dashboard;
