import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  MobileStepper,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TermsConditionsSignDialog from "../../common/components/dialogs/TermsConditionsSignDialog";
import { useAlerts } from "../../common/context/AlertContext";
import AdvancedRequestBaseForm from "./AdvancedRequestBaseForm";
import { useAdvancedRequestContext } from "./AdvancedRequestFormContext";
import AdvancedRequestProductForm from "./AdvancedRequestProductForm";
import AdvancedRequestReview from "./AdvancedRequestReview";
import AdvancedRequestsBreadcrumbs from "./AdvancedRequestsBreadcrumbs";
import SupplementalInfoForm from "./SupplementalInfoForm";

export default function EditAdvancedRequest({ isNew }) {
  const [lastClickedButton, setLastClickedButton] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { canEdit, advancedRequest, setAdvancedRequest, adminView } =
    useAdvancedRequestContext();
  const navigate = useNavigate();

  useEffect(() => {
    // Ensure someone can't navigate directly to the URL to edit an old request
    if (!canEdit) {
      navigate(`/app/buyer/product-requests`);
    }
  }, [navigate, canEdit]);

  const { addErrorAlert, addSuccessAlert } = useAlerts();

  const [formikRef, setFormikRef] = useState();
  const needsAgreement = advancedRequest.fulfillmentStatus !== "ORDER_PLACED";
  const [showTermsConditionsDialog, setShowTermsConditionsDialog] =
    useState(false);
  async function submitIfNeeded() {
    if (formikRef?.current) {
      setSubmitting(true);
      try {
        const updatedRequest = await formikRef.current.submitForm();
        // Formik bug. If form is invalid, submitForm() will return undefined instead of throwing an error
        if (!updatedRequest) {
          const errorMessages = Object.values(formikRef.current.errors);
          if (errorMessages.length > 0) {
            addErrorAlert(errorMessages[0]);
          } else {
            addErrorAlert("An error occurred. Please try again.");
          }
          setSubmitting(false);
          return false;
        } else {
          setAdvancedRequest(updatedRequest);
        }
      } catch (error) {
        return false;
      }
      setSubmitting(false);
    }
    return true;
  }

  async function handleNextAfterSignatureCheck() {
    const submitSuccess = await submitIfNeeded();
    if (!submitSuccess) {
      return;
    }
    if (currentStep === requestSteps.length - 1) {
      addSuccessAlert("Request submitted successfully");
      navigate(`/app/buyer/product-requests/${advancedRequest.id}`);
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  }

  async function handleNext() {
    setLastClickedButton("next");
    if (currentStep === requestSteps.length - 1 && needsAgreement) {
      setShowTermsConditionsDialog(true);
      return;
    } else {
      handleNextAfterSignatureCheck();
    }
  }

  async function handleBack() {
    setLastClickedButton("back");
    // When moving back, only submit the form if it has been changed
    if (formikRef?.current?.dirty) {
      const submitSuccess = await submitIfNeeded();
      if (!submitSuccess) {
        return;
      }
    }
    setCurrentStep((prevStep) => prevStep - 1);
  }

  const requestSteps = [
    {
      label: "Request Info",
      content: (
        <AdvancedRequestBaseForm
          advancedRequest={advancedRequest}
          setAdvancedRequest={setAdvancedRequest}
          isNew={isNew}
          setFormikRef={setFormikRef}
        />
      ),
    },
    {
      label: "Select Products",
      content: (
        <AdvancedRequestProductForm
          advancedRequest={advancedRequest}
          setAdvancedRequest={setAdvancedRequest}
          setFormikRef={setFormikRef}
        />
      ),
      stepManagesScrolling: true,
    },
    {
      label: "Additional Info",
      content: (
        <SupplementalInfoForm
          advancedRequest={advancedRequest}
          setAdvancedRequest={setAdvancedRequest}
          setFormikRef={setFormikRef}
        />
      ),
    },
    {
      label: "Review & Submit",
      content: (
        <AdvancedRequestReview
          advancedRequest={advancedRequest}
          setFormikRef={setFormikRef}
        />
      ),
    },
  ];

  const backDisabled = submitting || currentStep === 0;
  const nextDisabled = submitting;

  const backButton = (
    <Button
      onClick={handleBack}
      disabled={backDisabled}
      startIcon={
        submitting && lastClickedButton === "back" ? (
          <CircularProgress
            style={{ width: "20px", height: "20px" }}
            color="inherit"
          />
        ) : (
          <ChevronLeft />
        )
      }
    >
      Back
    </Button>
  );
  const nextButton = (
    <Button
      onClick={handleNext}
      disabled={nextDisabled}
      endIcon={
        submitting && lastClickedButton === "next" ? (
          <CircularProgress
            style={{ width: "20px", height: "20px" }}
            color="inherit"
          />
        ) : (
          <ChevronRight />
        )
      }
      color={currentStep === requestSteps.length - 1 ? "success" : undefined}
      variant={"contained"}
    >
      {currentStep === requestSteps.length - 1 ? `Submit` : `Next`}
    </Button>
  );

  return (
    <Container
      sx={{
        maxHeight: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        pt: 1.5,
        overflowY: requestSteps[currentStep].stepManagesScrolling
          ? "hidden"
          : "auto",
      }}
    >
      <AdvancedRequestsBreadcrumbs
        selectedRequest={advancedRequest}
        adminView={adminView}
      />
      <Box
        display={{ xs: "none", sm: "flex" }}
        sx={{ gap: 2, alignItems: "center", mb: 3 }}
      >
        {backButton}
        <Stepper activeStep={currentStep} sx={{ flexGrow: 1 }}>
          {requestSteps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {nextButton}
      </Box>
      <Divider />

      <Box
        data-testid="advanced-request-step-content"
        sx={{
          flex: "auto",
          pb: { xs: "52.5px", sm: "0" },
          maxHeight: "100%",
          height: "100%",
        }}
      >
        {requestSteps[currentStep].content}
      </Box>
      <Box display={{ xs: "block", sm: "none" }}>
        <MobileStepper
          variant="dots"
          steps={requestSteps.length}
          position="bottom"
          activeStep={currentStep}
          nextButton={nextButton}
          backButton={backButton}
          sx={{ width: "100%" }}
        />
      </Box>
      <TermsConditionsSignDialog
        open={showTermsConditionsDialog}
        onClose={() => setShowTermsConditionsDialog(false)}
        onSign={handleNextAfterSignatureCheck}
        typeOfTerms="buyer"
      />
    </Container>
  );
}
