import React from "react";
import TypographyEllipsis from "../data-display/TypographyEllipsis";

export default function ProductSaleFocusDisplay({ product }) {
  return (
    <TypographyEllipsis
      data-testid="product-instance-sale-focus"
      variant="body2"
      sx={{ ml: 1.5, width: "120px", textAlign: "center" }}
    >
      {product.saleFocus}
    </TypographyEllipsis>
  );
}
