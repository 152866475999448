import { Logout, Person } from "@mui/icons-material";
import {
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import UserAvatar from "../common/components/UserAvatar";
import { useAuthContext } from "../common/context/AuthContext";
import { displayName } from "../common/util/DisplayUtil";

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { currentUser, logOut, isMasquerading, masqueradeLogout } =
    useAuthContext();

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function onClose() {
    setAnchorEl(null);
  }

  return (
    <List>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          id="profile-menu-button"
          sx={{
            minHeight: 48,
            justifyContent: "initial",
            px: 2.5,
          }}
          onClick={handleClick}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 3,
              justifyContent: "center",
            }}
          >
            <Badge
              badgeContent="!"
              color="error"
              invisible={!isMasquerading}
              id="masquerade-warning-icon"
            >
              <UserAvatar id="current-user-avatar" user={currentUser} />
            </Badge>
          </ListItemIcon>
          <ListItemText primary={"My Account"} sx={{ opacity: 1 }} />
        </ListItemButton>
        <Menu
          id="profile-menu"
          MenuListProps={{
            "aria-labelledby": "profile-menu-btn-",
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <ListItem id="menu-active-user-name">
            {displayName(currentUser.firstName, currentUser.lastName)}
          </ListItem>
          <MenuItem
            component={Link}
            to={`/app/business-admin/manage-users/${currentUser.id}`}
            onClick={() => setAnchorEl(null)}
          >
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>
          {isMasquerading && (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                masqueradeLogout();
              }}
            >
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <ListItemText
                primary="Stop Masquerading"
                secondary={`Back to ${displayName(currentUser.masqueradeUser.firstName, currentUser.masqueradeUser.lastName)}`}
              />
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              logOut();
            }}
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText>Sign Out</ListItemText>
          </MenuItem>
        </Menu>
      </ListItem>
    </List>
  );
}
