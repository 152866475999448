import moment from "moment";
import { COLLECTIVE_PROPERTY } from "../context/CollectiveContext";
export function isOrderFulfillmentDate(pickupDate, collective) {
  if (!pickupDate) {
    return false;
  }
  // Check if date is a moment object
  if (!pickupDate.isoWeekday) {
    pickupDate = moment(pickupDate);
  }

  const dayOfWeek = pickupDate.isoWeekday();
  const daysOfWeek = collective.properties[
    COLLECTIVE_PROPERTY.ORDER_FULFILLMENT_DAYS_OF_WEEK
  ]
    .split(",")
    .map(Number);
  switch (collective.subdomain) {
    default: {
      return daysOfWeek.includes(dayOfWeek);
    }
  }
}

export function isValidBringToMarketDay(date, collective) {
  if (!date) {
    return false;
  }
  // Check if date is a moment object
  if (!date.isoWeekday) {
    date = moment(date);
  }
  const daysOfWeek = collective.properties[
    COLLECTIVE_PROPERTY.SUPPLIER_DROPOFF_DAYS_OF_WEEK
  ]
    .split(",")
    .map(Number);
  const dayOfWeek = date.isoWeekday();
  switch (collective.subdomain) {
    default: {
      return daysOfWeek.includes(dayOfWeek);
    }
  }
}

export function getNextOrderFulfillmentDate(collective) {
  const today = moment().startOf("day");
  switch (collective.subdomain) {
    case "picklinq":
    default:
      // Add days until we get to a valid market day
      while (!isOrderFulfillmentDate(today, collective)) {
        today.add(1, "day");
      }
      return today;
  }
}

export function isPresaleShoppingAvailable(collective) {
  const nextMarketDate = getNextOrderFulfillmentDate(collective);
  const presaleOpenDaysInAdvance = Number(
    collective.properties[COLLECTIVE_PROPERTY.PRESALE_OPEN_DAYS_IN_ADVANCE]
  );
  // Times are hh:mm in 24-hour format
  const presaleOpenTime =
    collective.properties[COLLECTIVE_PROPERTY.PRESALE_OPEN_TIME];
  const presaleCloseDaysInAdvance = Number(
    collective.properties[COLLECTIVE_PROPERTY.PRESALE_CLOSE_DAYS_IN_ADVANCE]
  );
  const presaleCloseTime =
    collective.properties[COLLECTIVE_PROPERTY.PRESALE_CLOSE_TIME];
  switch (collective.subdomain) {
    default:
      // Market opens at 5pm 5 days before
      const presaleShoppingBegins = nextMarketDate
        .clone()
        .subtract(presaleOpenDaysInAdvance, "days");
      presaleShoppingBegins.set({
        hour: presaleOpenTime.split(":")[0],
        minute: presaleOpenTime.split(":")[1],
        second: 0,
        millisecond: 0,
      });
      // Market closes at 3pm the day before
      const presaleShoppingEnds = nextMarketDate
        .clone()
        .subtract(presaleCloseDaysInAdvance, "day");
      presaleShoppingEnds.set({
        hour: presaleCloseTime.split(":")[0],
        minute: presaleCloseTime.split(":")[1],
        second: 0,
        millisecond: 0,
      });
      return true;
    // return moment().isBetween(presaleShoppingBegins, presaleShoppingEnds);
  }
}

export function isPresaleUploadingAvailableForDate(d, collective) {
  // Check if date is a moment object
  if (!d.isoWeekday) {
    d = moment(d);
  }
  // Times are hh:mm in 24-hour format
  const presaleCutoffDaysInAdvance = Number(
    collective.properties[COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_DAYS_IN_ADVANCE]
  );
  const presaleCutoffTime =
    collective.properties[COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_TIME];

  switch (collective.subdomain) {
    case "picklinq":
    default:
      // Presale uploads end at 3pm 5 days before the market
      const uploadDeadline = d
        .clone()
        .subtract(presaleCutoffDaysInAdvance, "days");
      uploadDeadline.set({
        hour: presaleCutoffTime.split(":")[0],
        minute: presaleCutoffTime.split(":")[1],
        second: 0,
        millisecond: 0,
      });
      return true;
    // return moment().isBefore(uploadDeadline);
  }
}

export function isBeforeCutoffToEditProductRequest(requestDate, collective) {
  if (!requestDate) {
    return false;
  }
  // Check if date is a moment object
  if (!requestDate.isoWeekday) {
    requestDate = moment(requestDate);
  }
  switch (collective.subdomain) {
    case "picklinq":
    default:
      // For picklinq, disallow editing the Sunday before the market
      const now = moment();

      const startOfWeekForMarket = requestDate.clone().startOf("week");
      return startOfWeekForMarket.isAfter(now);
  }
}
