import { Container, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import ReportContainer from "../../../common/components/data-display/ReportContainer";
import { useAuthContext } from "../../../common/context/AuthContext";
import AgedOutService from "../../../common/service/AgedOutService";
import AgedOutByColorReportDisplay from "./AgedOutByColorReportDisplay";
import AgedOutByProductReportDisplay from "./AgedOutByProductReportDisplay";
import AgedOutBySupplierReportDisplay from "./AgedOutBySupplierReportDisplay";
import AgedOutByWeekReportDisplay from "./AgedOutByWeekReportDisplay";
import AgedOutReportCriteria from "./AgedOutReportCriteria";

const AgedOutReport = () => {
  const { isOwnerOfCollective, defaultSupplier } = useAuthContext();
  return (
    <Container sx={{ pt: 1.5 }}>
      <Typography variant="h2" gutterBottom data-testid="aged-out-report-title">
        Aged Out Report
      </Typography>
      <ReportContainer
        reportTitle="Aged Out Report"
        initialReportCriteria={{
          groupBy: isOwnerOfCollective ? "supplier" : "product",
          startDate: moment().startOf("year").format("YYYY-MM-DD"),
          endDate: moment().endOf("year").format("YYYY-MM-DD"),
          supplier: isOwnerOfCollective ? null : defaultSupplier,
        }}
        validationSchema={Yup.object().shape({
          groupBy: Yup.string().required("Group By is required"),
          startDate: Yup.string().required("Start Date is required"),
          endDate: Yup.string().required("End Date is required"),
          supplier: isOwnerOfCollective
            ? Yup.object().nullable()
            : Yup.object().nullable().required("Supplier is required"),
        })}
        fetchReportData={AgedOutService.fetchAgedOutReport}
        ReportCriteriaComponent={AgedOutReportCriteria}
        ReportComponent={(reportProps) => {
          switch (reportProps.reportCriteria.groupBy) {
            case "primaryColor":
              return <AgedOutByColorReportDisplay {...reportProps} />;
            case "product":
              return <AgedOutByProductReportDisplay {...reportProps} />;
            case "supplier":
              return <AgedOutBySupplierReportDisplay {...reportProps} />;
            case "week":
              return <AgedOutByWeekReportDisplay {...reportProps} />;
            default:
              return null;
          }
        }}
      />
    </Container>
  );
};

export default AgedOutReport;
