import { Save } from "@mui/icons-material";
import { Button, Container, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Field, Formik, getIn } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Form } from "react-router-dom";
import * as Yup from "yup";
import FormColorInput from "../../../common/components/form/FormColorInput.jsx";
import { FormQuantityField } from "../../../common/components/form/FormQuantityField.jsx";
import FormSelect from "../../../common/components/form/FormSelect.jsx";
import { FormTextField } from "../../../common/components/form/FormTextField.jsx";
import { useAlerts } from "../../../common/context/AlertContext.jsx";
import {
  COLLECTIVE_PROPERTY,
  useCollective,
} from "../../../common/context/CollectiveContext.jsx";
import CollectiveService from "../../../common/service/CollectiveService.js";

const daysOfWeekConfigRegex = /^([0-6](,[0-6])*)?$/;
const twentyFourHourTimeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
const defaultCollectiveInfo = {
  name: "",
  primaryColor: "",
  secondaryColor: "",
  properties: {
    [COLLECTIVE_PROPERTY.HOW_TO_PAY_INSTRUCTIONS]: "",
    [COLLECTIVE_PROPERTY.REPLY_TO_EMAIL]: "",
    [COLLECTIVE_PROPERTY.CONTACT_PHONE]: "",
    [COLLECTIVE_PROPERTY.ADVANCED_REQUEST_MAX_DAYS]: 28,
    [COLLECTIVE_PROPERTY.CART_TIMEOUT_MINUTES]: 20,
    [COLLECTIVE_PROPERTY.MARKET_CLOSED_MESSAGE]: "",
    [COLLECTIVE_PROPERTY.IS_MARKET_CLOSED]: "open",
    [COLLECTIVE_PROPERTY.SUPPLIER_DROPOFF_DAYS_OF_WEEK]: "",
    [COLLECTIVE_PROPERTY.ORDER_FULFILLMENT_DAYS_OF_WEEK]: "",
    [COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_DAYS_IN_ADVANCE]: 0,
    [COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_TIME]: 0,
    [COLLECTIVE_PROPERTY.PRESALE_OPEN_DAYS_IN_ADVANCE]: 0,
    [COLLECTIVE_PROPERTY.PRESALE_OPEN_TIME]: 0,
    [COLLECTIVE_PROPERTY.PRESALE_CLOSE_DAYS_IN_ADVANCE]: 0,
    [COLLECTIVE_PROPERTY.PRESALE_CLOSE_TIME]: 0,
  },
};

function displayDaysOfWeek(daysOfWeekStr) {
  if (!daysOfWeekStr) {
    return "";
  }
  if (daysOfWeekConfigRegex.test(daysOfWeekStr) === false) {
    return "Invalid days of week.";
  }
  return daysOfWeekStr
    .split(",")
    .map((dayOfWeek) => moment().set("weekday", dayOfWeek).format("dddd"))
    .join(", ");
}

const EditMyCollective = () => {
  const { reloadCollective } = useCollective();
  const [loading, setLoading] = useState(true);
  const { addErrorAlert, addSuccessAlert } = useAlerts();
  const formikRef = React.useRef();
  const [collectiveInfo, setCollectiveInfo] = useState(defaultCollectiveInfo);

  useEffect(() => {
    setLoading(true);
    CollectiveService.getCurrentCollectiveInfo()
      .then((response) => {
        setCollectiveInfo(response);
        formikRef.current.setValues({ ...response });
      })
      .catch((error) => addErrorAlert("Error fetching collective info", error))
      .finally(() => setLoading(false));
  }, [addErrorAlert]);

  const handleSubmit = async (collectiveInfoToSave) => {
    return CollectiveService.updateCollective(
      collectiveInfo.id,
      collectiveInfoToSave
    )
      .then((updatedCollective) => {
        formikRef.current.resetForm({ values: updatedCollective });
        reloadCollective();
        addSuccessAlert("Changes saved");
      })
      .catch((error) => {
        addErrorAlert("Error saving changes", error);
      });
  };

  return (
    <Container sx={{ pt: 1.5 }} data-testid="edit-collective-container">
      <Typography variant="h1" gutterBottom data-testid="edit-collective-title">
        Configure Collective
      </Typography>
      {loading && <Skeleton variant="rectangular" height={200} />}
      <Formik
        initialValues={collectiveInfo}
        onSubmit={handleSubmit}
        innerRef={formikRef}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
          primaryColor: Yup.string().required("Required"),
          secondaryColor: Yup.string().required("Required"),
          properties: Yup.object().shape({
            [COLLECTIVE_PROPERTY.HOW_TO_PAY_INSTRUCTIONS]:
              Yup.string().required("Required"),
            [COLLECTIVE_PROPERTY.REPLY_TO_EMAIL]: Yup.string()
              .email("Invalid email")
              .required("Required"),
            [COLLECTIVE_PROPERTY.CONTACT_PHONE]:
              Yup.string().required("Required"),
            [COLLECTIVE_PROPERTY.SUPPLIER_DROPOFF_DAYS_OF_WEEK]: Yup.string()
              .matches(
                daysOfWeekConfigRegex,
                "Invalid days of week. Must be a comma-separated list of numbers 0-6 without spaces."
              )
              .required("Required"),
            [COLLECTIVE_PROPERTY.ORDER_FULFILLMENT_DAYS_OF_WEEK]: Yup.string()
              .matches(
                daysOfWeekConfigRegex,
                "Invalid days of week. Must be a comma-separated list of numbers 0-6 without spaces."
              )
              .required("Required"),
            [COLLECTIVE_PROPERTY.ADVANCED_REQUEST_MAX_DAYS]:
              Yup.number().required("Required"),
            [COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_DAYS_IN_ADVANCE]:
              Yup.number().required("Required"),
            [COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_TIME]: Yup.string()
              .matches(
                twentyFourHourTimeRegex,
                "Time must be a valid time in 24 hour format (eg, enter 15:00 for 3:00pm)."
              )
              .required("Required"),
            [COLLECTIVE_PROPERTY.CART_TIMEOUT_MINUTES]:
              Yup.number().required("Required"),
            [COLLECTIVE_PROPERTY.PRESALE_OPEN_DAYS_IN_ADVANCE]: Yup.number()
              .required("Required")
              .test(
                "PRESALE_OPEN_DAYS_IN_ADVANCE",
                "Presale open days must be less than or equal to upload cutoff days",
                function (value) {
                  const cutoffDays = getIn(
                    formikRef.current?.values,
                    `properties.${COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_DAYS_IN_ADVANCE}`
                  );
                  return parseInt(value) <= parseInt(cutoffDays);
                }
              ),
            [COLLECTIVE_PROPERTY.PRESALE_OPEN_TIME]: Yup.string()
              .matches(
                twentyFourHourTimeRegex,
                "Time must be a valid time in 24 hour format (eg, enter 15:00 for 3:00pm)."
              )
              .test(
                "PRESALE_OPEN_TIME",
                "Presale open time must come after cutoff time",
                function (value) {
                  if (
                    getIn(
                      formikRef.current?.values,
                      `properties.${COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_DAYS_IN_ADVANCE}`
                    ) ===
                    getIn(
                      formikRef.current?.values,
                      `properties.${COLLECTIVE_PROPERTY.PRESALE_OPEN_DAYS_IN_ADVANCE}`
                    )
                  ) {
                    return (
                      moment(value, "HH:mm") >
                      moment(
                        getIn(
                          formikRef.current?.values,
                          `properties.${COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_TIME}`
                        ),
                        "HH:mm"
                      )
                    );
                  }
                  return true;
                }
              )
              .required("Required"),

            [COLLECTIVE_PROPERTY.PRESALE_CLOSE_DAYS_IN_ADVANCE]: Yup.number()
              .required("Required")
              .test(
                "PRESALE_CLOSE_DAYS_IN_ADVANCE",
                "Presale close days must be less than or equal to presale open days in advance",
                function (value) {
                  const openInAdvanceDays = getIn(
                    formikRef.current?.values,
                    `properties.${COLLECTIVE_PROPERTY.PRESALE_OPEN_DAYS_IN_ADVANCE}`
                  );
                  return parseInt(value) <= parseInt(openInAdvanceDays);
                }
              ),
            [COLLECTIVE_PROPERTY.PRESALE_CLOSE_TIME]: Yup.string()
              .matches(
                twentyFourHourTimeRegex,
                "Time must be a valid time in 24 hour format (eg, enter 15:00 for 3:00pm)."
              )
              .test(
                "PRESALE_CLOSE_TIME",
                "Presale close time must come after open time",
                function (value) {
                  if (
                    getIn(
                      formikRef.current?.values,
                      `properties.${COLLECTIVE_PROPERTY.PRESALE_OPEN_DAYS_IN_ADVANCE}`
                    ) ===
                    getIn(
                      formikRef.current?.values,
                      `properties.${COLLECTIVE_PROPERTY.PRESALE_CLOSE_DAYS_IN_ADVANCE}`
                    )
                  ) {
                    return (
                      moment(value, "HH:mm") >
                      moment(
                        getIn(
                          formikRef.current?.values,
                          `properties.${COLLECTIVE_PROPERTY.PRESALE_OPEN_TIME}`
                        ),
                        "HH:mm"
                      )
                    );
                  }
                  return true;
                }
              )
              .required("Required"),
          }),
        })}
      >
        {({ values, ...props }) => (
          <Form autoComplete="off" data-testid="edit-collective-form">
            <Typography variant="h4" gutterBottom>
              Collective Info
            </Typography>
            {!loading && collectiveInfo && (
              <>
                <Grid container spacing={2}>
                  <Grid
                    xs={12}
                    md={5}
                    container
                    spacing={2}
                    alignContent="start"
                  >
                    <Grid xs={12}>
                      <Field
                        component={FormTextField}
                        label="Name"
                        name="name"
                      />
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <Field
                        component={FormColorInput}
                        label="Primary Color"
                        name="primaryColor"
                      />
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <Field
                        component={FormColorInput}
                        label="Secondary Color"
                        name="secondaryColor"
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Field
                        component={FormSelect}
                        label="Market Status"
                        name={`properties.${COLLECTIVE_PROPERTY.IS_MARKET_CLOSED}`}
                        options={[
                          { label: "Open", value: "open" },
                          { label: "Closed", value: "closed" },
                        ]}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Field
                        component={FormTextField}
                        label="Market Closed Message"
                        name={`properties.${COLLECTIVE_PROPERTY.MARKET_CLOSED_MESSAGE}`}
                        multiline
                        rows={4}
                        placeholder="The market is closed for the holiday..."
                        helperText="This message will appear to users when the market is configured to be closed."
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} md={7}>
                    <Grid container spacing={3} columns={12}>
                      <Grid xs={12}>
                        <Field
                          component={FormTextField}
                          label="How To Pay Instructions"
                          name={`properties.${COLLECTIVE_PROPERTY.HOW_TO_PAY_INSTRUCTIONS}`}
                          multiline
                          rows={4}
                          placeholder="To pay, mail a check to..."
                          helperText="Instructions for how to pay for orders. This will appear in emails sent to customers."
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid xs={12} md={7}>
                        <Field
                          component={FormTextField}
                          label="Reply To Email Address"
                          name={`properties.${COLLECTIVE_PROPERTY.REPLY_TO_EMAIL}`}
                          type="email"
                          helperText="Email address for customer replies to order confirmations and other emails."
                        />
                      </Grid>
                      <Grid xs={12} md={5}>
                        <Field
                          component={FormTextField}
                          label="Contact Phone"
                          name={`properties.${COLLECTIVE_PROPERTY.CONTACT_PHONE}`}
                          type="tel"
                          placeholder="123-456-7890"
                          helperText="Phone number for customer inquiries."
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 2 }} alignItems="start">
                  <Grid xs={12}>
                    <Typography variant="h4" gutterBottom>
                      Market Configuration
                    </Typography>
                    <Typography variant="body2">
                      Days: 0=Sun, 1=Mon, 2=Tue, 3=Wed, 4=Thu, 5=Fri, 6=Sat
                    </Typography>
                  </Grid>
                  <Grid xs={12} md={6} container>
                    <Grid xs={12} md={5}>
                      <Field
                        component={FormTextField}
                        label="Supplier Dropoff Days"
                        name={`properties.${COLLECTIVE_PROPERTY.SUPPLIER_DROPOFF_DAYS_OF_WEEK}`}
                        helperText="Days of the week that suppliers can drop off products."
                      />
                    </Grid>
                    <Grid xs={12} md={7}>
                      {displayDaysOfWeek(
                        getIn(
                          values,
                          `properties.${COLLECTIVE_PROPERTY.SUPPLIER_DROPOFF_DAYS_OF_WEEK}`
                        )
                      )}
                    </Grid>
                    <Grid xs={12} md={5}>
                      <Field
                        component={FormTextField}
                        label="Order Fulfillment Days"
                        name={`properties.${COLLECTIVE_PROPERTY.ORDER_FULFILLMENT_DAYS_OF_WEEK}`}
                        helperText="Days of the week that orders are fulfilled."
                      />
                    </Grid>
                    <Grid xs={12} md={7}>
                      {displayDaysOfWeek(
                        getIn(
                          values,
                          `properties.${COLLECTIVE_PROPERTY.ORDER_FULFILLMENT_DAYS_OF_WEEK}`
                        )
                      )}
                    </Grid>
                    <Grid xs={12}>
                      <Field
                        component={FormQuantityField}
                        label="Advanced Request Max Days"
                        name={`properties.${COLLECTIVE_PROPERTY.ADVANCED_REQUEST_MAX_DAYS}`}
                        helperText="Users can request products up to this many days in advance."
                        sx={{ maxWidth: "200px" }}
                        min={0}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Field
                        component={FormQuantityField}
                        label="Presale Cart Timeout (minutes)"
                        name={`properties.${COLLECTIVE_PROPERTY.CART_TIMEOUT_MINUTES}`}
                        helperText="Presale carts will stay alive for this many minutes."
                        sx={{ maxWidth: "200px" }}
                      />
                    </Grid>
                  </Grid>

                  <Grid xs={12} md={6} container>
                    <Grid xs={12}>
                      <Typography variant="h6">
                        Supplier Upload Cutoff for Presale
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Field
                        component={FormQuantityField}
                        label="Supplier Upload Presale Cutoff Days"
                        name={`properties.${COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_DAYS_IN_ADVANCE}`}
                        placeholder="eg, 5"
                        helperText="Suppliers can upload products for presale up to this many days in advance."
                        min={0}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Field
                        component={FormTextField}
                        label="Supplier Upload Presale Cutoff Time"
                        name={`properties.${COLLECTIVE_PROPERTY.UPLOAD_CUTOFF_TIME}`}
                        placeholder="eg, 15:00"
                        helperText="The time of day that suppliers can no longer upload products for presale."
                      />
                    </Grid>
                    <Grid xs={12}>
                      <Typography variant="h6">
                        Presale Availability Starts
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Field
                        component={FormQuantityField}
                        label="Presale Open Days in Advance"
                        name={`properties.${COLLECTIVE_PROPERTY.PRESALE_OPEN_DAYS_IN_ADVANCE}`}
                        placeholder="eg, 5"
                        helperText="Buyers will be able to access the presale up to this many days in advance."
                        min={0}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Field
                        component={FormTextField}
                        label="Presale Open Time"
                        name={`properties.${COLLECTIVE_PROPERTY.PRESALE_OPEN_TIME}`}
                        placeholder="eg, 15:00"
                        helperText="The time of day that buyers will be able to access the presale."
                      />
                    </Grid>

                    <Grid xs={12}>
                      <Typography variant="h6">
                        Presale Availability Ends
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Field
                        component={FormQuantityField}
                        label="Presale Close Days in Advance"
                        name={`properties.${COLLECTIVE_PROPERTY.PRESALE_CLOSE_DAYS_IN_ADVANCE}`}
                        placeholder="eg, 5"
                        helperText="Buyers will no longer be able to access the presale up to this many days in advance."
                        min={0}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Field
                        component={FormTextField}
                        label="Presale Close Time"
                        name={`properties.${COLLECTIVE_PROPERTY.PRESALE_CLOSE_TIME}`}
                        placeholder="eg, 15:00"
                        helperText="The time of day that buyers will no longer be able to access the presale."
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={<Save />}
                  disabled={props.isSubmitting}
                  onClick={props.submitForm}
                  data-testid="save-button"
                >
                  Save
                </Button>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default EditMyCollective;
