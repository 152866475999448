import { Paper } from "@mui/material";
import { Field } from "formik";
import moment from "moment";
import DebouncedTextField from "../../../common/components/form/DebouncedTextField";
import FormCheckboxWithLabel from "../../../common/components/form/FormCheckboxWithLabel";
import { FormDatePicker } from "../../../common/components/form/FormDatePicker";
import { useCollective } from "../../../common/context/CollectiveContext";
import { isOrderFulfillmentDate } from "../../../common/util/DatePickerUtilFrontend";

export default function SupplierViewProductRequestSearchCriteria() {
  const { collectiveInfo } = useCollective();
  return (
    <Paper
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 100,
        p: 1,
        display: "flex",
        gap: 2,
        flexWrap: "wrap",
      }}
      elevation={0}
    >
      <Field
        component={DebouncedTextField}
        label="Search..."
        name="searchText"
        sx={{ maxWidth: 300, width: "100%" }}
      />

      <div>
        <Field
          component={FormDatePicker}
          required
          disablePast
          name="pickupDate"
          label="Pickup Date"
          sx={{ maxWidth: 300, width: "100%" }}
          size="small"
          inputProps={{ "data-testid": "pickup-date" }}
          shouldDisableDate={(d) => !isOrderFulfillmentDate(d, collectiveInfo)}
          maxDate={moment().add(4, "weeks")}
        />
      </div>
      <div>
        <Field
          component={FormCheckboxWithLabel}
          name="showOnlyUnfulfilled"
          label="Show only unfulfilled"
          size="small"
        />
      </div>
      <div>
        <Field
          component={FormCheckboxWithLabel}
          name="myCommitmentsOnly"
          label="Show my commitments"
          size="small"
        />
      </div>
    </Paper>
  );
}
